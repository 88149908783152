.worker-account {
#workeraccount–messages {
  display: none;
}
#worker-account–messages-conversation {
  display: none;
}

.workeraccount–messages {
  .button-primary {
    margin-left: 10px;
  }
  .container {
    border-radius: 0px 15px 15px 15px !important;
    padding: 0;
  }
  .content-header {
    display: flex;
    padding-bottom: 20px;

    .sorting-table {
      margin-right: 15px;
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        margin-right: 25px;
        margin-bottom: 0;
        @media only screen and (max-width: 991px) {
          font-size: 14px;
        }
      }

      .sorting-dropdown {
        font-size: 16px;
        height: 50px;
        width: 270px;
      }
      @media only screen and (max-width: 991px) {
        .sorting-dropdown {
          font-size: 14px;
        }
      }
    }

    .input-custom-data {
      display: flex;
      cursor: pointer;
      padding: 5px 30px 5px 15px;
      height: 50px;
      align-items: center;
      border-radius: 10px;
      border: 1px solid $mainDark;
      @media only screen and (max-width: 650px) {
        margin-top: 20px;
        max-width: 200px;
      }

      img {
        height: 28px;
        width: auto;
        margin-right: 8px;
      }

      span {
        font-size: 16px;
        @media only screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }

    .button-primary {
      margin-left: 15px;
    }

    @media only screen and (max-width: 991px) {
      padding-bottom: 30px;
      .sorting-table {
        display: block;
        p {
          margin-bottom: 5px;
        }
      }
      .input-custom-data {
        width: 300px;
        margin-top: 26px;
      }
      .button-primary {
        margin-top: 26px;
        width: auto;
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 30px;
      .sorting-table {
        margin: 0 0 10px;
        p {
          margin-bottom: 5px;
        }
        .sorting-dropdown {
          width: 100%;
        }
      }
      .input-custom-data {
        margin: 0 0 10px;
        width: 100%;
        max-width: none;
      }
      .button-primary {
        margin: 0;
        width: 100%;
      }
    }
  }
  .tabs-typo {
    padding-bottom: 30px;
  }

  .pagination {
    margin-top: 30px;
    justify-content: flex-end;
  }
}

.message-table {
  margin: 0 -15px;
  padding: 0 15px;
  .message-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;

    @media only screen and (max-width: 991px) {
      justify-content: space-between;
    }

    @media only screen and (max-width: 650px) {
      flex-wrap: wrap;
      flex-direction: column;
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }

    .name {
      margin-left: 40px;
      margin-right: 30px;
      max-width: 20%;
      width: 15%;
      white-space: nowrap;
      overflow: hidden;

      @media only screen and (max-width: 991px) {
        max-width: 30%;
        width: 30%;
      }

      @media only screen and (max-width: 650px) {
        width: calc(100% - 55px);
        max-width: calc(100% - 55px);
        margin-left: 15px;
        display: flex;
      }

      .fullname {
        @media only screen and (max-width: 650px) {
          overflow: hidden;
          margin-right: 10px;
          font-weight: 700;
        }

        @media only screen and (max-width: 450px) {
          width: calc(100% - 125px);
        }
      }

      .date-mobil {
        display: none;
        width: 115px;

        @media only screen and (max-width: 650px) {
          display: block;
        }
      }
    }

    .date {
      margin-right: 30px;

      @media only screen and (max-width: 991px) {
        order: 3;
        width: 107px;
      }

      @media only screen and (max-width: 650px) {
        display: none;
      }
    }

    .message {
      display: flex;
      margin-right: 30px;
      max-width: 53%;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (max-width: 1200px) {
        max-width: 50%;
      }

      @media only screen and (max-width: 991px) {
        max-width: 30%;
        width: 30%;
      }

      @media only screen and (max-width: 705px) {
        max-width: 30%;
        width: 30%;
      }

      @media only screen and (max-width: 650px) {
        width: calc(100% - 55px);
        max-width: calc(100% - 55px);
        margin-left: 15px;
        display: flex;

        margin-top: 6px;
        opacity: 0.7;
      }

      .title {
      }

      .masaage-excerpt {
        opacity: 0.7;
        margin-left: 10px;
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
    }

    .button-open {
      width: 40px;
      margin-left: auto;

      @media only screen and (max-width: 991px) {
        order: 4;
        margin-left: 15px;
      }

      @media only screen and (max-width: 650px) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .hover {
        display: none;
      }
    }

    //AS9864
    //     &.new {
    //       font-weight: 700;
    //     }

    &:hover {
      background-color: $brandOrange;
      color: $textLight;

      .button-open {
        .hover {
          display: block;
        }

        img:not(.hover) {
          display: none;
        }
      }
    }
  }
}

.worker-account–messages-conversation {
  .content-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid $mainDark;
    margin-bottom: 30px;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      padding-top: 15px;
      padding-bottom: 30px;
    }

    @media only screen and (max-width: 991px) {
      border-bottom: 0;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 20px;
      align-items: flex-start;
    }

    .name {
      font-size: 18px;
      font-weight: 500;

      p {
        margin-bottom: 0;
      }

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 600px) {
        margin-top: 15px;
        font-size: 12px;
      }
    }

    .additional-text {
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .content-body {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 991px) {
      flex-direction: column;
      .message-area {
        width: 100%;
      }
    }
  }

  .content-sidebar {
    width: 265px;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .tabs-message,
    .tabs-review {
      width: 100%;

      .tab {
        width: 100%;
        .img-mobile {
          display: none;
          height: 16px;
          width: auto;
          margin-right: 20px;

          @media only screen and (max-width: 991px) {
            display: block;
          }
        }
        @media only screen and (max-width: 991px) {
          padding: 20px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid $mainLight;
          width: auto;
          cursor: pointer;
          background-color: transparent;
          color: $mainDark;
        }

        @media only screen and (max-width: 768px) {
          padding: 10px 20px;
        }
      }
    }
  }

  .message-area {
    width: calc(100% - 295px);

    .box-conversation-review {
      margin-bottom: 30px;
      width: 100%;

      .box-content {
        margin-top: 0;
      }

      .review-info {
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          flex-wrap: wrap;
        }

        .type {
          @media screen and (max-width: 1200px) and (min-width: 992px) {
            border: none;
          }
        }

        .worker {
          @media screen and (max-width: 1200px) and (min-width: 992px) {
            width: 100%;
            padding-left: 0;
          }
        }
      }

      &.answered {
        margin-bottom: 0;
        border-radius: 5px 5px 0px 0px;
        padding-bottom: 15px;

        .divider {
          text-align: center;
          border-top: 1px solid $backgroundWhite;
          padding-top: 15px;
          font-size: 14px;
          font-weight: 500;

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }

    .box-conversation-review-answer {
      padding-top: 15px;
      margin-bottom: 30px;

      .box-content {
        margin: 0;
      }
    }

    .box-conversation-title {
      margin-bottom: 30px;
      width: 100%;
    }

    .box-conversation-wrapper {
      display: flex;
      flex-direction: column;

      .box-conversation-received {
        width: 90%;
        margin-bottom: 30px;

        .contact-deatils {
          margin-top: 15px;

          p {
            margin-bottom: 0;
            font-weight: 700;
          }
        }
      }

      .box-conversation-sent {
        margin-bottom: 30px;
        width: 90%;
        margin-left: auto;
      }
    }
  }
}

.worker-account–review-open {
  .text-alert {
    p {
      font-size: 16px;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .conversation-closed {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-top: 15px;
    border-top: 2px solid $mainDark;
    @media only screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
  .button-accept {
    .button-white {
      font-size: 16px;
      color: $brandOrange;
      width: 100%;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .button-conversation {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid rgba($mainDark, 0.1);
    @media only screen and (max-width: 1200px) {
      padding-top: 30px;
      margin-top: 0;
    }
    .button-secondary {
      font-size: 16px;
      width: 100%;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
}

.tabs-message {
  .tab {
    min-width: 260px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $mainLight;
    width: max-content;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 991px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 768px) {
      padding: 9px 20px;
    }

    p {
      margin-bottom: 0;
    }

    .button-open {
      display: none;
      width: 40px;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 991px) {
        display: flex;
      }
      @media only screen and (max-width: 600px) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
      }
    }

    .tab-content {
      @media only screen and (max-width: 991px) {
        display: flex;
        align-items: center;
        width: 100%;
      }
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      .name {
        font-size: 16px;
        margin-bottom: 7px;

        @media only screen and (max-width: 991px) {
          margin-bottom: 0;
          width: calc(50% - 20px);
          margin-right: 15px;
          overflow: hidden;
        }
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 600px) {
          margin-bottom: 10px;
        }
      }

      .alerts {
        font-size: 14px;
        font-weight: 700;

        @media only screen and (max-width: 991px) {
          margin-bottom: 0;
          width: calc(50% - 20px);
          margin-right: 15px;
          overflow: hidden;
        }
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    &.tab-icon {
      display: flex;

      .icon-alert {
        flex: 0 0 auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: block;
        margin-right: 18px;

        &.positive {
          background-color: $alertCorrect;
        }

        &.negative {
          background-color: $alertError;
        }
      }
    }

    &.active {
      border-color: $brandOrange;
      background-color: $brandOrange;
      color: $textLight;
    }
  }
}

#worker-account–messages-conversation {
  #back-to-conversations {
    display: none;
  }
  @media screen and (max-width: 991px) {
    #back-to-list {
      display: inline-block;
    }
    #tabs-messages {
      display: block;
    }
    #back-to-conversations,
    .message-area {
      display: none;
    }
    &.active {
      #back-to-list,
      #tabs-messages {
        display: none;
      }
      #back-to-conversations {
        display: inline-block;
      }
      .message-area {
        display: block;
      }
    }
  }
}
}