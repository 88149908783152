.modal-backdrop {
  background-color: $brandBlue !important;
  opacity: 0.7;
}

.modal {
  .close-btn {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "\d7";
      font-size: 90px;
      color: #fff;
      line-height: 100px;
      text-align: center;
      cursor: pointer;
    }
  }
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    min-height: 100%;
    max-width: unset;
    border-radius: 15px;
    @media (min-width: 768px) {
      padding: 15px;
    }
    .modal-content {
      max-width: 600px;
      border: 0;
      @media (min-width: 992px) {
        &.modal-lg,
        &.modal-xl {
          min-width: 900px;
        }
      }
      .modal-title {
        font-size: 24px;
        padding: 25px 25px 15px 25px;
      }
      hr {
        margin: 30px 0;
      }
      .modal-header {
        .modal-title {
          padding: 0;
        }
      }
    }
  }
}

#main-report-popup {
  .modal-dialog {
    .modal-content {
      @media (max-width: 767px) {
        max-width: 300px !important;
        min-width: 300px !important;
        .modal-body {
          .main-img {
            height: 300px;
          }
        }
      }

      @media (min-width: 991px) {
        max-width: 768px !important;
      }
    }
  }
}