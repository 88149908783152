.user-account {
.user-account–messages {
  .header-content {
    display: flex;
    margin-bottom: 10px;
    .table-options {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      @media only screen and (max-width: 680px) {
        display: none;
      }
      .custom-checkbox {
        margin-right: 40px;
        @media only screen and (max-width: 991px) {
          display: none;
        }
        span {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      .button-delate {
        img {
          margin-right: 5px;
        }
      }
      .unselect-all {
        display: none;
      }
    }
    .tabs-typo {
      margin-left: auto;
      @media only screen and (max-width: 680px) {
        margin-left: 0;
      }
    }
    @media only screen and (max-width: 640px) {
      flex-wrap: wrap;
      .table-options {
        &.active {
          padding: 10px 15px;
          margin: 0 -15px;
          display: flex;
          justify-content: space-between;
          order: 2;
          width: 100vw;
          background: $mainLight;
        }
        .button-delate {
          order: 2;
          font-size: 12px;
        }
        .unselect-all {
          display: block;
          font-size: 12px;
          img {
            margin-right: 5px;
            height: 18px;
            width: auto;
          }
        }
      }
    }
  }
}

#user-account–messages {
  display: none;
}
}