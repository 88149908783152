.worker-account {
.accept-opinion-button {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $mainLight;
  color: $brandOrange;
  border-radius: 10px;
  &:focus {
    outline: 0;
  }
}

.message-area-private {
  width: 100%;
  #render-messages-place {
    width: 100%;
  }

  .box-conversation-review {
    margin-bottom: 30px;
    width: 100%;

    .box-content {
      margin-top: 0;
    }

    .review-info {
      @media screen and (max-width: 1200px) and (min-width: 992px) {
        flex-wrap: wrap;
      }

      .type {
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          border: none;
        }
      }

      .worker {
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          width: 100%;
          padding-left: 0;
        }
      }
    }

    &.answered {
      margin-bottom: 0;
      border-radius: 5px 5px 0px 0px;
      padding-bottom: 15px;

      .divider {
        text-align: center;
        border-top: 1px solid $backgroundWhite;
        padding-top: 15px;
        font-size: 14px;
        font-weight: 500;

        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
      }
    }
  }

  .box-conversation-review-answer {
    padding-top: 15px;
    margin-bottom: 30px;

    .box-content {
      margin: 0;
    }
  }

  .box-conversation-title {
    margin-bottom: 30px;
    width: 100%;
  }

  .box-conversation-wrapper {
    display: flex;
    flex-direction: column;

    .box-conversation-received {
      width: 90%;
      margin-bottom: 30px;

      .contact-deatils {
        margin-top: 15px;

        p {
          margin-bottom: 0;
          font-weight: 700;
        }
      }
    }

    .box-conversation-sent {
      margin-bottom: 30px;
      width: 90%;
      margin-left: auto;
    }
  }
}

.opinion-wrapper {
  display: flex;
  justify-content: space-around;
}

#closed-conversation {
  display: none;
}
.worker-account–review-open {
  .content-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid $mainDark;
    margin-bottom: 30px;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      padding-top: 15px;
      padding-bottom: 30px;
    }

    @media only screen and (max-width: 991px) {
      border-bottom: 0;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 20px;
      align-items: flex-start;
    }

    .name {
      font-size: 18px;
      font-weight: 500;

      p {
        margin-bottom: 0;
      }

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 600px) {
        margin-top: 15px;
        font-size: 12px;
      }
    }

    .additional-text {
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .content-body {
    display: flex;
    justify-content: space-between;
  }

  .content-sidebar {
    width: 265px;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .tabs-message,
    .tabs-review {
      width: 100%;
      @media only screen and (max-width: 991px) {
        display: flex;
        overflow: auto;
      }

      .tab {
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 20px;
        width: 100%;
        padding: 15px 10px;
        cursor: pointer;
        .img-mobile {
          display: none;
          height: 16px;
          width: auto;
          margin-right: 20px;

          @media only screen and (max-width: 991px) {
            display: block;
          }
        }
        @media only screen and (max-width: 991px) {
          margin-right: 10px;
          padding: 15px 20px;
          width: auto;
          &:last-of-type {
            margin-right: 0;
          }
          img {
            height: 36px;
            width: auto;
          }
        }
        @media only screen and (max-width: 768px) {
          flex: 1 1 auto;
          padding: 10px 15px;
          min-width: 0;
          width: 50%;
          img {
            height: 22px;
            width: auto;
            margin-right: 10px;
          }
          .tab-content {
            .name {
              margin: 0;
              text-transform: capitalize;
            }
            .hide-mobile {
              display: none;
            }
          }
        }
      }

      .active {
        background-color: $brandOrange;
        p {
          color: white;
        }
        @media only screen and (max-width: 991px) {
          border-radius: 5px;
          border: 1px solid $mainLight;
          cursor: pointer;
          color: $mainDark;
        }
      }
    }
  }

  .message-area {
    width: calc(100% - 295px);

    @media only screen and (max-width: 991px) {
      display: none;
    }

    .box-conversation-review {
      margin-bottom: 30px;
      width: 100%;

      .box-content {
        margin-top: 0;
      }

      .review-info {
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          flex-wrap: wrap;
        }

        .type {
          @media screen and (max-width: 1200px) and (min-width: 992px) {
            border: none;
          }
        }

        .worker {
          @media screen and (max-width: 1200px) and (min-width: 992px) {
            width: 100%;
            padding-left: 0;
          }
        }
      }

      &.answered {
        margin-bottom: 0;
        border-radius: 5px 5px 0px 0px;
        padding-bottom: 15px;

        .divider {
          text-align: center;
          border-top: 1px solid $backgroundWhite;
          padding-top: 15px;
          font-size: 14px;
          font-weight: 500;

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }

    .box-conversation-review-answer {
      padding-top: 15px;
      margin-bottom: 30px;

      .box-content {
        margin: 0;
      }
    }

    .box-conversation-title {
      margin-bottom: 30px;
      width: 100%;
    }

    .box-conversation-wrapper {
      display: flex;
      flex-direction: column;

      .box-conversation-received {
        width: 90%;
        margin-bottom: 30px;

        .contact-deatils {
          margin-top: 15px;

          p {
            margin-bottom: 0;
            font-weight: 700;
          }
        }
      }

      .box-conversation-sent {
        margin-bottom: 30px;
        width: 90%;
        margin-left: auto;
      }
    }
  }

  &.mobile-convertation {
    .message-area {
      width: 100%;

      @media only screen and (max-width: 991px) {
        display: block;
      }
    }
  }
}

.worker-account–review-open {
  .text-alert {
    p {
      font-size: 16px;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .conversation-closed {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-top: 15px;
    border-top: 2px solid $mainDark;
    @media only screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
  .button-accept {
    .button-white {
      font-size: 16px;
      color: $brandOrange;
      width: 100%;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .button-conversation {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid rgba($mainDark, 0.1);
    @media only screen and (max-width: 1200px) {
      padding-top: 30px;
      margin-top: 0;
    }
    .button-secondary {
      font-size: 16px;
      width: 100%;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
}
}