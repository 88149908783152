.select-default {
  border: 1px solid $selectBorderColor;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 10px;
  &:focus {
    outline: none;
  }
}
.custom-checkbox {
  position: relative;
  min-height: 1.75rem;

  &:before {
    border-color: white;
  }

  .text-check {
    opacity: 1;
    display: inline;
    color: white;
  }

  .text-uncheck {
    opacity: 0;
    display: none;
  }
  .custom-control-label:after {
      // Feather check icon
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' aria-hidden='true' focusable='false' viewBox='0 0 24 24'%3E%3Cpath d='M6 10l-2 2l6 6L20 8l-2-2l-8 8z' fill='%23fff'/%3E%3C/svg%3E")!important;
      background-size: 80%;
      opacity: 0;
      transition: opacity .3s;
  }

  .custom-control-label:before {
    border: 2px solid $mainDark;
    width: 20px;
    height: 20px;
  }
  .custom-control-input {
    left: 0px;
    top: 9px;
    width: 20px;
    height: 20px;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    background-color: $brandOrange;
    border-color: $brandOrange;
  }
  input[type="checkbox"]:checked ~ .custom-control-label:before {
    background-color: $brandOrange;
    border-color: $brandOrange;
  }
  input[type="checkbox"]:checked ~ .custom-control-label:after {
    opacity: 1;
  }
  input[type="checkbox"] {
      display: none;
  }

};


.radio-buttons {
  height: fit-content;
  padding: 25px;
  .button-primary-full {
      margin-top: 25px;
  }
.radio-container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    height: 22px;
    display: flex;
    align-items: center;
    p {
        font-size: 16px;
        margin-top: 18px;
        margin-left: 10px;
    }
  }
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 2px solid;
    border-radius: 50%;
  }
  .radio-container:hover input ~ .checkmark-radio {
    background-color: $mainLight;
  }
  .radio-container input:checked ~ .checkmark-radio {
    background-color: $brandOrange;
    border: 0;
  }
  .checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
  }
  .radio-container input:checked ~ .checkmark-radio:after {
    display: block;
  }
}
