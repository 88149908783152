// Base colors

$brandBlue: #0062aa;
$brandOrange: #f6a335;
$mainDark: #354052;
$mainLight: #f3f3f3;
$backgroundLight: #d5f0f2;
$backgroundDark: #8ccdd5;
$backgroundWhite: #ffffff;
$alertCorrect: #00aa55;
$alertError: #eb0505;
$fullBlack: #000;

// Additional colors

$buttonTextColor: #ffffff;
$textLight: #ffffff;
$selectBorderColor: #404040;
$shadowColor: rgb(224, 224, 224);
$overlayColor: rgba(128, 128, 128, 0.8);
$shadowColorTransparent: rgba(224, 224, 224, 0.5);
$shadowColorTransparentSecondary: rgba(255, 255, 255, 0.95);
$shadowButton: #0000001A;
$disabledColor: #D3D3D3;
$notActiveText: #A0A0A0;