@import "../../../../global/homepage/scss/colors.scss";

.dealership-card-big-wrapper {
  box-shadow: 0px 0px 16px $shadowColor;
  border-radius: 10px;
  overflow: hidden;

  .dealership-card-big-main {
    min-height: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .dealership-photo {
      flex: 1 0 auto;
      padding-bottom: 80px;
      width: 100%;
      height: 320px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dealership-information {
      width: 100%;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        180deg,
        #ffffff00 0%,
        #ffffff0b 0,
        #fff 30%,
        #fff 100%
      );
      padding: 60px 20px 10px;
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 5px;
        text-decoration: none !important;
        color: $mainDark;
      }
      .address {
        font-size: 14px;
        font-weight: lighter;
        margin: 0 0 6px 0;
      }
      .star-generate-wrapper {
        margin: 5px 0;
      }
      .dealer-marks {
        display: flex;
        flex-wrap: wrap;
        display: none;
        margin: 0 0 20px;
        .dealer-mark {
          margin-right: 10px;
          margin-top: 20px;
        }
      }
      .footer {
        display: none;
        position: absolute;
        right: 15px;
        bottom: 10px;
        .arrow-blue {
          padding: 3px;
        }
        span {
          margin-right: 5px;
          color: $brandBlue;
          font-size: 11px;
          font-weight: 500;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }

  .dealership-card-big-content {
    padding: 6px 20px;
    background-color: $brandOrange;
    width: 100%;
    font-size: 16px;
    color: $textLight;
  }

  .dealership-card-big-seller {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background: $mainLight;
    .dealership-seller-card-wrapper {
      width: 100%;
      padding: 3px 0;
//       flex-direction: column;
      display: flex;
      &:nth-child(2) {
        border-left: 1px solid $shadowColor;
        border-right: 1px solid $shadowColor;
      }
      .dealership-seller-card-photo {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        height: 110px;
        padding: 0px 0px 0px 20px;
        img {
          max-width: 150px;
        }
      }
      .dealership-seller-card {
        position: relative;
        width: 50%;
        padding: 5px 10px;
        .name-row {
          font-size: 12px;
          display: flex;
          flex-direction: row;
          line-height: 26px;
          img {
            width: 20px;
            height: auto;
          }
          a {
//             text-align: center;
//             padding-top: 5px;
            padding-left: 5px;
            color: $mainDark;
            cursor: pointer;
            text-decoration: none !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .star-row {
//           margin: 0 auto;
        }
        .link-row {
          text-align: center;
          margin-top: 10px;
          position: absolute;
//           right: 15px;
          bottom: 15px;
          a {
            text-decoration: none;
            font-weight: 500;
            span {
              font-size: 15px;
              color: $brandBlue;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .dealership-card-big-wrapper {
    .dealership-card-big-main {
      flex-direction: row;
      .dealership-photo {
        position: relative;
        display: block;
        width: 35%;
        height: auto;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .dealership-information {
        margin-left: 15px;
        position: relative;
        top: 0;
        padding: 0 20px 20px;
        background: none;
        position: unset;
        height: auto;
        .title {
          font-size: 24px;
          margin-bottom: 10px;
          -webkit-line-clamp: 2;
        }
        .address-container {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          .address {
            font-size: 14px;
            &:last-of-type {
              &:after {
                content: "•";
                font-size: 40px;
                line-height: 10px;
                vertical-align: text-top;
                margin: 0 5px;
              }
            }
          }
        }

        .star-generate-wrapper {
          margin: 15px 0;
        }
        .dealer-marks {
          display: flex;
          .dealer-mark {
            margin-right: 10px;
          }
        }
        .footer {
          display: block;
          position: relative;
          right: 0;
          bottom: 0;
          text-align: right;
          .arrow-blue {
            padding: 4px;
          }
          span {
            margin-right: 15px;
            font-size: 15px;
          }
        }
      }
    }

    .dealership-card-big-content {
      padding: 12px 40px;
    }

    .dealership-card-big-seller {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      .dealership-seller-card-wrapper {
        width: calc(100% / 3);
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        .dealership-seller-card-photo {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          height: 110px;
          padding: 0px 0px 0px 20px;
          img {
            max-width: 150px;
          }
        }
        .dealership-seller-card {
          width: 100%;
          height: 110px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 5px 0px;
          .name-row {
            margin-left: 5px;
            font-size: 14px;
            flex-direction: row;
            width: fit-content;
            &.no-certified {
              margin-left: 20px;
            }
            a {
//               margin-left: 5px;
              padding-top: 0;
              text-align: left;
            }
          }
          .star-row {
            margin-left: 30px;
          }
          .link-row {
            margin-left: 30px;
            margin-top: 10px;
            position: unset;
            text-align: left;
            a {
              span {
                font-size: 14px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
