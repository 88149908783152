.offer-contact-form {
  &--container {
    padding-top: 100px;

    &--info {
      min-width: 400px;

      &--brand {
        text-align: left;
        font: normal normal 500 26px/34px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
      }

      &--gross {
        text-align: right;
        font: normal normal bold 26px/34px Montserrat, serif;
        letter-spacing: 0;
        color: #f6a335;
      }

      &--model {
        text-align: left;
        font: normal normal 500 16px/30px Montserrat, serif;
        letter-spacing: 0;
        color: black;
        opacity: 1;
      }

      &--net {
        text-align: right;
        font: normal normal 500 16px/30px Montserrat, serif;
        letter-spacing: 0;
        color: #f6a335;
      }

      &--divider {
        height: 2px;
        background: #0000001a;
      }

      &--image {
        height: 179px;
        width: 100%;
        border-radius: 15px;
      }

      &--details {
        background: #f3f3f3;
        border-radius: 15px;

        @media screen and (min-width: 768px) {
          background: inherit;
        }

        &--title {
          text-align: center;
          font: normal normal 300 12px/20px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
        }

        &--value {
          text-align: center;
          font: normal normal 500 18px/25px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          border-bottom: 1px solid #0062aa;
          opacity: 1;
        }
      }

      &--icons {
        font: normal normal 500 12px/9px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
      }
    }
  }
}
