.verification-sent-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mainLight;
  flex-direction: column;
  padding: 120px 20px;

  min-height: calc(100vh - 90px - 260px);
  h1 {
    margin-bottom: 30px;
    color: $brandOrange;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    max-width: 300px;
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 90px - 160px);
    padding: 80px 30px;
    h1 {
      margin-bottom: 40px;
      font-size: 20px;
    }
    p {
      font-size: 16px;
      max-width: 500px;
    }
  }
  @media only screen and (min-width: 1200px) {
    min-height: calc(100vh - 90px - 135px);
    padding: 100px 50px;
    h1 {
      margin-bottom: 50px;
      font-size: 28px;
    }
    p {
      font-size: 20px;
      max-width: 1000px;
    }
  }
}

.confirm-email-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mainLight;
  flex-direction: column;
  padding: 50px 20px;

  min-height: calc(100vh - 90px - 260px);
  h1 {
    margin-bottom: 30px;
    color: $brandOrange;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    max-width: 300px;
    text-align: center;
    a {
        text-decoration: none;;
    }
  }
  form {
      button {
          margin-top: 40px;
      }
  }
  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 90px - 160px);
    padding: 80px 30px;
    h1 {
      margin-bottom: 40px;
      font-size: 20px;
    }
    p {
      font-size: 16px;
      max-width: 500px;
    }
  }
  @media only screen and (min-width: 1200px) {
    min-height: calc(100vh - 90px - 135px);
    padding: 100px 50px;
    h1 {
      margin-bottom: 50px;
      font-size: 28px;
    }
    p {
      font-size: 20px;
      max-width: 1000px;
    }
  }
}
