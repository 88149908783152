.flat-pages-wrapper {
    min-height: calc(100vh - 90px - 260px);
    padding-top: 130px;
    max-width: 340px;
    margin: 0 auto;
    @media only screen and (min-width: 768px) {
        min-height: calc(100vh - 90px - 160px);
        max-width: 700px;
    }
    @media only screen and (min-width: 1200px) {
        min-height: calc(100vh - 90px - 135px);
        max-width: 1200px;
    }
}

.error-page {
    padding: 90px 0;
    box-shadow: inset 0px -30px 50px -50px rgba(0, 0, 0, .5);
    .error-img {
        width: 100%;
        height: auto;
    }
    .error-message {
        margin: 40px 0 0;
        padding: 0 20px;
        text-align: center;
        .button-secondary {
            margin-top: 50px;
        }
        @media only screen and (max-width: 767px) {
            h1 {
                font-size: 1.5rem
            }
            h2 {
                font-size: 1rem;
            }
            .button-secondary {
                margin-top: 30px;
            }
        }
    }
}
