$brandBlue: #0062aa;

.reports {
  .header {
    position: relative;
    padding-top: 139px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .header .header-content {
    flex-grow: 1;
    max-width: 1000px;
    text-align: center;
  }

  .header .header-content .header-title .up {
    font-size: calc(25px + (60 - 25) * ((50vw - 300px) / (1300 - 300)));
    font-weight: 500;
    color: #444;
    margin-bottom: 5px;
    position: relative;
    display: block;
  }

  .header .header-content .header-title .down {
    font-size: calc(20px + (75 - 30) * ((50vw - 300px) / (1300 - 300)));
    margin-bottom: 0;
    position: relative;
    display: block;
  }

  .about {
    display: flex;
    text-align: left;
  }

  .about-img-holder {
    min-width: 200px;
    max-width: 400px;
    margin-right: 20px;
  }

  .about-img-holder .about-img {
    width: 100%;
    margin-bottom: 15px;
  }

  .about-caption {
    flex-grow: 1;
  }

  @media (max-width: 767.98px) {
    .about {
      flex-direction: column;
      text-align: center;
    }

    .about-img-holder {
      max-width: 220px;
      min-width: 100%;
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
    }
  }

  /* sections */
  .section {
    padding: 20px 10px;
  }

  .section .section-title {
    font-size: calc(15px + (35 - 15) * ((100vw - 300px) / (1300 - 300)));
  }

  .section .section-subtitle {
    margin-bottom: 6px;
    font-weight: 400;
    opacity: 0.6;
  }

  .section-sm {
    padding: 40px 0 !important;
  }

  .section-md {
    padding: 60px 0 !important;
  }

  .section-lg {
    padding: 100px 0 !important;
  }

  .sponsor-card {
    position: relative;
    min-height: 230px;
    border: 1px solid #dee2e6;
    overflow: hidden;
    border-radius: 3px;
  }

  .sponsor-card .body {
    padding: 10px;
  }

  .sponsor-card .body img.icon {
    margin-top: 40px;
    max-width: 160px;
    max-height: 60px;
    margin-bottom: 25px;
    transition: all, 0.3s;
  }

  @media (prefers-reduced-motion: reduce) {
    .sponsor-card .body img.icon {
      transition: none;
    }
  }

  .sponsor-card .body .subtitle {
    position: absolute;
    bottom: -100%;
    opacity: 0;
    visibility: hidden;
    transition: all, 0.3s;
  }

  @media (prefers-reduced-motion: reduce) {
    .sponsor-card .body .subtitle {
      transition: none;
    }
  }

  .sponsor-card:hover .body img.icon {
    margin-top: 5px;
    width: 45px;
    margin-bottom: 15px;
  }

  .sponsor-card:hover .body .subtitle {
    bottom: 7px;
    opacity: 1;
    visibility: visible;
  }

  /* portfolio wrapper */
  .portfolio-card {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .portfolio-card .portfolio-card-img {
    width: 100%;
    opacity: 0.1;
  }

  .portfolio-card .portfolio-card-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .portfolio-card .portfolio-card-overlay .portfolio-card-caption {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .testimonial-card-img-holder {
    min-width: 100px;
    max-width: 100px;
    z-index: 999;

    @media (max-width: 520px) {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    .portfolio-card {
      margin-bottom: 30px;
    }

    .service-card {
      margin-bottom: 30px;
    }
  }

  /* testimonial card */
  .testimonial-card {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    min-width: 515px;

    @media (max-width: 767.98px) {
      min-width: 350px;
    }

    @media (max-width: 520px) {
      min-width: 250px;
    }
  }

  .testimonial-card-img {
    background: #fff;
    padding: 4px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .testimonial-card-body {
    text-align: left;
    border: 1px solid #dee2e6;
    background: #fff;
    padding: 20px 20px 20px 60px;
    border-radius: 0;

    @media (max-width: 520px) {
      padding: 10px;
    }
  }

  .testimonial-card-title {
    margin-top: 20px;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1300 - 300)));
  }

  .pricing-wrapper .pricing-card-list p i {
    color: #ec185d;
  }

  .bg-primary {
    background: $brandBlue !important;
  }
}
