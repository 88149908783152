.how-it-works {
  &--container {
    padding-top: 139px;

    &--top {
      background: #ffff 0% 0% no-repeat padding-box;
      box-shadow: 0 2px 4px #0000000d;
      padding: 33px;

      img {
        @media screen and (min-width: 541px) {
          width: 500px;
        }

        @media screen and (max-width: 540px) {
          width: 350px;
        }
      }
    }

    &--bottom {
      margin-top: 10px;
      padding: 33px;

      &--col {
        h5 {
          font: normal normal medium 22px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
        }

        p {
          letter-spacing: 0.22px;
        }
      }
    }
  }
}
