.worker-account {
.tabs-typo {
  display: flex;
  .tabs {
    flex: 1 1 auto;
    padding-left: 0;
    display: flex;
    list-style: none;
    .tab {
      cursor: pointer;
      a {
        display: block;
        margin-bottom: 0;
        padding: 14px 30px;
        font-size: 12px;
        color: $mainDark;
        font-weight: 600;
        border-bottom: 3px solid transparent;
        text-decoration: none !important;
        @media only screen and (max-width: 991px) {
          padding: 14px 18px;
        }
        @media only screen and (max-width: 769px) {
          padding: 8px 15px;
          font-size: 14px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 12px;
          white-space: nowrap;
          padding: 5px 10px;
        }
        .additional {
          @media only screen and (max-width: 600px) {
            display: none;
          }
        }
      }
      p {
        display: block;

        margin-bottom: 0;
        padding: 14px 30px;
        font-size: 12px;
        color: $mainDark;
        font-weight: 600;
        border-bottom: 3px solid transparent;
        text-decoration: none !important;
        @media only screen and (max-width: 991px) {
          padding: 14px 18px;
        }
        @media only screen and (max-width: 769px) {
          padding: 8px 15px;
          font-size: 14px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 12px;
          white-space: nowrap;
          padding: 5px 10px;
        }
        .additional {
          @media only screen and (max-width: 600px) {
            display: none;
          }
        }
      }

      &.active {
        a {
          color: $brandOrange;
          border-bottom: 3px solid $brandOrange;
        }
        p {
          color: $brandOrange;
          border-bottom: 3px solid $brandOrange;
        }
        span {
          color: $brandOrange;
        }
      }
    }
  }
}
.worker-account-main {
  .main-tab {
    padding: 0;
    .tabs-big {
      .tabs {
        padding-left: 0;
        display: flex;
        margin-bottom: 0 !important;
        .tab {
          list-style: none;
          width: max-content;
          display: flex;
          margin-right: 2px;
          background-color: $mainDark;
          font-weight: 500;
          a {
            padding: 13px 30px;
            color: $buttonTextColor;
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 700;
            text-decoration: none;
            @media only screen and (max-width: 576px) {
              padding: 15px;
            }
            p {
              margin: 0;
              color: inherit;
            }
          }

          &.active {
            background-color: $backgroundWhite;

            a {
              color: $mainDark;
              font-weight: 700;
            }
          }

          &.tab-disabled {
            background: #ccc;
          }

          &:first-of-type {
            border-radius: 10px 0px 0px 0px;
          }

          &:last-of-type {
            border-radius: 0px 10px 0px 0px;
          }
        }
      }
    }
  }
}
.tabs-review {
  .tab {
    min-width: 260px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $mainLight;
    width: max-content;
    display: flex;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    .tab-content {
      .name {
        font-size: 16px;
        margin-bottom: 7px;
      }

      .alerts {
        font-size: 14px;
        font-weight: 700;
      }
    }

    img {
      margin-right: 13px;

      &.hover {
        display: none;
      }
    }

    &.active {
      border-color: $brandOrange;
      background-color: $brandOrange;
      color: $textLight;

      img {
        &:not(.hover) {
          display: none;
        }

        &.hover {
          display: inline-block;
        }
      }
    }
  }
}

.worker-account-main {
  .tabs-typo {
    .tabs-tools {
      @media only screen and (max-width: 576px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: white;
        padding: 10px 15px;
        text-align: center;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
}