.user-account {
.user-account–review {
  .header-content.mobile {
    display: none;

    @media only screen and (max-width: 1200px) {
      display: block;
    }
    .tabs {
      @media only screen and (max-width: 650px) {
        flex-wrap: wrap;
      }
    }
    .button-delate {
      order: 2;
      font-size: 12px;
      float:left;
    }
  }

  .header-content {
    &:not(.mobile) {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .button-delate {
      order: 2;
      font-size: 12px;
      float:left;
    }
  }

  .review-content-box {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    .box-info {
      width: 340px;
      // Show Opinions tab according to UX
      margin-right: 2rem;
      @media only screen and (max-width: 1200px) {
        width: 100%;
        margin-bottom: 40px;
        margin-right: 0;
      }
    }

    .user-opinion-table {
      .row {
        margin-left: 0;
        margin-right: 0;
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }
    }

    .review-content-wrapper {
      width: 100%;
    }
  }
}
.user-account–review-open {
  .box-conversation-received {
    width: 90%;
  }
}
#user-account–review {
  display: none;
}

@media only screen and (min-width: 1200px) {
  #collapse-info {
    pointer-events: none;
  }
}
.desktop {
  display: block !important;
}

@media only screen and (min-width: 1200px) {
  .desktop {
    display: none !important;
  }
}

#user-account–review-open {
  display: none;
  .header-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

}
.box-conversation-review {
  margin: 40px 0;
  @media only screen and (max-width: 769px) {
    padding: 15px;
    margin-top: 0;
  }
}
}