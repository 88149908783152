// @import "../../../../global/homepage/scss/colors.scss";

.dealer-card-small {
  display: flex;
  height: 140px;
  width: 360px;
  position: relative;
  padding-top: 30px;
  margin: 15px auto;
  background: $backgroundWhite;
  .photo-card {
    display: flex;
    width: fit-content;
    max-width: 160px;
    .radio-buttons {
      height: 110px !important;
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      .radio-container {
        margin: 0;
        width: fit-content !important;
        height: 22px !important;
        padding-left: 30px !important;
      }
    }
    img {
      height: 110px;
      max-width: 110px;
      object-fit: cover;
    }
  }
  .card-content {
    .card-header {
      padding: 6px;
      background: $brandOrange;
      position: absolute;
      width: 100%;
      display: flex;
      top: 0;
      left: 0;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      .certificated-dealer {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-certificate {
          height: 20px;
        }
        p {
          font-size: 10px;
          color: $textLight;
          text-transform: uppercase;
          margin: 0 5px;
          white-space: nowrap;
        }
      }
      .star-generate-wrapper {
        margin-left: 5px;
        .star-generate {
          margin-top: 2px;
          font-size: 12px;
          color: $textLight;
          font-weight: normal;
          .rate-star {
            height: 11px;
            margin: 0 1px;
          }
        }
        span {
          font-size: 12px;
          color: $textLight;
          font-weight: normal;
        }
      }
    }
    .worker-data {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      height: 110px;
      .worker-name {
        font-size: 16px;
        margin: 0;
      }
      .worker-position {
        margin: 5px 0 0 0;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    margin: 25px;
    width: 520px;
    padding-top: 0;
    height: 200px;
    .photo-card {
      display: flex;
      width: fit-content;
      height: 100%;
      max-width: 220px;
      position: relative;
      .radio-buttons {
        height: 100% !important;
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: $shadowColorTransparentSecondary;
        .radio-container {
          width: fit-content !important;
          height: 22px !important;
          padding-left: 30px !important;
        }
      }
      img {
        margin-left: 50px;
        height: 100%;
        width: 170px;
        max-width: 220px;
        object-fit: cover;
      }
    }
    .card-content {
      width: inherit;
      .card-header {
        position: static;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        padding: 6px 10px;
        align-items: initial;
        .certificated-dealer {
          display: flex;
          .icon-certificate {
            height: 28px;
          }
          p {
            font-size: 14px;
          }
        }
        .star-generate-wrapper {
          margin-left: 5px;
          .star-generate {
            margin-top: 4px;
            margin-left: 20px;
            font-size: 15px;
            .rate-star {
              height: 15px;
            }
          }
          span {
            font-size: 15px;
          }
        }
      }
      .worker-data {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        flex: 1;
        .worker-name {
          font-size: 18px;
          margin: 0;
        }
        .worker-position {
          margin: 5px 0 0 0;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
  }
}
