.opinions {
  &--h2 {
    font: normal normal normal 12px/15px Montserrat, serif;
    letter-spacing: 0;
    color: #ffff;
    opacity: 1;
    margin: auto;

    @media screen and (min-width: 768px) {
      font: normal normal normal 18px/34px Montserrat, serif;
    }
  }

  &--container {
    &--wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    &--header {
      background: #404040 0 0 no-repeat padding-box;
      opacity: 1;
      display: flex;
      height: 54px;

      &--left {
        border-radius: 10px 0 0;
      }

      &--right {
        border-radius: 0 15px 0 0;
      }
    }

    &--button {
      margin-top: 27px;

      @media screen and (min-width: 768px) {
        margin-top: 21px;
      }

      .col {
        display: flex;
      }

      button {
        width: 495px;
        height: 50px;
        background: #f6a335 0 0 no-repeat padding-box;
        box-shadow: 0 10px 20px #e0e0e033;
        border-radius: 15px;
        text-align: center;
        font: normal normal medium 14px/8px Montserrat, serif;
        letter-spacing: 0;
        color: #ffff;
        opacity: 1;
      }
    }

    &--checkbox {
      margin-top: 29px;

      @media screen and (min-width: 768px) {
        margin-top: 25px;
      }

      label {
        text-align: left;
        font: normal normal normal 12px/15px Montserrat, serif;
        letter-spacing: 0;
        color: black;
        opacity: 1;

        @media screen and (min-width: 768px) {
          font: normal normal normal 16px/8px Montserrat, serif;
        }
      }

      input {
        margin-left: 72px;
        background: #f6a335 0 0 no-repeat padding-box;
        border: 2px solid #f6a335;
        opacity: 1;
      }
    }

    &--newest {
      margin-top: 12px;

      @media screen and (min-width: 768px) {
        margin-top: 26px;
      }

      p {
        text-align: left;
        font: normal normal bold 18px/34px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
        margin-left: 14px;
        margin-bottom: 0;
      }
    }

    &--inside-wrapper {
      margin-top: 14px;

      &--element {
        &--name {
          text-align: left;
          font: normal normal 600 10px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 2px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &--user {
          text-align: left;
          font: normal normal normal 10px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 2px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &--tile {
          text-align: center;
          font: italic normal 600 12px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-bottom: 2px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &--content {
          text-align: center;
          font: italic normal normal 12px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-bottom: 2px;
          overflow: hidden;
          overflow-wrap: anywhere;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &--stars {
          width: 50px;

          &--icon {
            height: 13px;
          }
        }
      }

      &--element:last-of-type {
        display: none;

        @media screen and (min-width: 960px) {
          display: inline-block;
        }
      }
    }

    &--how {
      display: flex;
      height: 30px;

      a {
        text-align: left;
        text-decoration: underline;
        font: normal normal normal 10px/8px Montserrat, serif;
        letter-spacing: 0;
        color: black;
        opacity: 1;
        margin: auto;
        margin-right: 20px;
      }
    }

    &--dropdown {
      margin-top: 26px;

      @media screen and (min-width: 768px) {
        margin-top: 37px;
      }

      .col {
        height: 100%;
        display: flex;
      }
    }

    &--dropdown .col:last-of-type {
      margin-top: 13px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
    }

    background: #ffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }
}

.homepage-wrapper .homepage-search-section .homepage-form {
  height: auto;
}
