@import "../../../../global/homepage/scss/colors.scss";
@import "../../components/opinionCards/opinionCards.scss";

a.opinion-link{
  color:inherit
}
a.opinion-link:link{
  color: inherit;
}
a.opinion-link:visited{
  color:inherit;
}

.not-display-component {
  display: none !important;
}

.single-worker-wrapper {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  overflow: hidden;
  position: relative;

  .main-container {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 414px;
  }

  #send-message-form,
  #set-up-interview-form {
    .form {
      input,
      select,
      textarea {
        width: 100%;
      }
      .separator {
        margin: 0 10px;
      }
    }
  }

  .up-btn {
    position: fixed;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba($fullBlack, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background: $backgroundWhite;
    height: 30px;
    width: 30px;
    margin-left: 10px;
    bottom: 30px;
    right: 30px;
    .arrow-blue {
      padding: 4px;
      margin-top: 4px;
    }
  }

  .desktop {
    display: none !important;
  }

  .information-tab {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $mainLight;
    .information-tab-row {
      margin: 0 auto;
      display: flex;
      align-items: center;
      max-width: 414px;
      width: 100%;
      min-height: 90px;
      justify-content: space-between;

      .information-tab-content {
        display: flex;
        font-size: 16px;
        width: 50%;
        min-height: 90px;

        .information-tab-website {
          display: flex;
          align-items: center;
          margin: 5px;
          white-space: nowrap;
          a {
            .button-secondary-icon {
              width: 170px;
              background: #ffffff;
              color: #0062aa;
              transition: all 0.2s;
              border-color: #0062aa;
              border-style: solid;
              border-width: 1px;
              display: flex;
              justify-content: center;
              &:hover {
                color: white;
                background: #0062aa;
                img {
                  filter: brightness(0) invert(1);
                }
              }
              img {
                margin-right: 5px;
              }
            }
          }
        }
        .information-tab-phones {
          display: flex;
          justify-content: center;
          white-space: nowrap;
          margin: 5px;
        }
      }
      .information-dealer {
        margin-left: 20px;
        a {
          font-weight: bold;
          font-size: 18px;
          color: $brandBlue;
        }
      }
    }
  }

  .card-opinion-wrapper {
    .dealer-card {
      margin: 0 -15px;
      .dealer-photo-wrapper {
        position: relative;
        .dealer-photo {
          width: 100%;
          height: 350px;
          object-fit: cover;
        }
        .certificated-dealer {
          z-index: 1;
          position: absolute;
          bottom: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          height: 40px;
          background: $brandOrange;
          align-items: center;
          p {
            margin: 0;
            font-size: 12px;
            text-transform: uppercase;
            color: $textLight;
          }
          .icon-certificate {
            margin-right: 20px;
            height: 20px;
          }
        }
        .photo-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(
            180deg,
            #ffffff00 10%,
            #ffffff0b 0%,
            #ffffffe6 80%,
            #ffffff 100%
          );
        }
      }
      .card-information-wrapper {
        margin: -120px 15px 20px;
        position: relative;
        padding-bottom: 70px;
        z-index: 1;
        .card-title {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .social-icons {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          position: absolute;
          top: -215px;
          right: 5px;
          .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $backgroundWhite;
            box-shadow: 0px 0px 16px $shadowColor;
            width: 31px;
            height: 31px;
            border-radius: 5px;
            margin: 5px;
            cursor: pointer;
            img {
              height: 20px;
              width: 20px;
              object-fit: contain;
            }
          }
        }
        .card-data {
          margin: 0 0 3px 0px;
          font-size: 14px;
          text-transform: uppercase;
        }
        .button-secondary {
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          padding: 0 10px;
          position: absolute;
          left: 0px;
          top: -215px;
        }
      }
    }
    .opinion-dealer {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .button-secondary {
        height: 50px;
        line-height: 50px;
        width: 45%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        &:hover {
          color: white;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .opinion {
        display: flex;
        flex-direction: column;
        .opinion-data {
          display: flex;
          align-items: center;
          .percentage {
            font-size: 29px;
            margin-right: 5px;
            margin-bottom: 0;
            color: $brandBlue;
          }
          .opinion-text {
            font-size: 10px;
            text-transform: uppercase;
            margin-bottom: 0;
            color: $brandBlue;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .result-information {
    font-size: 12px;
    margin: 0 0 15px 0;
    img {
      margin: 0 5px 3px 0;
    }
    a {
      color: $mainDark;
    }
  }

  @media only screen and (min-width: 768px) {
    .main-container {
      padding: 0 20px;
      max-width: 808px;
    }
    .information-tab {
      .information-tab-row {
        padding: 0 20px;
        max-width: 808px;
      }
    }
    .result-information {
      font-size: 14px;
      margin: 20px 0 30px 0;
    }

    .card-opinion-wrapper {
      display: flex;
      justify-content: space-between;
      .dealer-card {
        margin: 0;
        width: 450px;
        display: flex;
        .dealer-photo-wrapper {
          width: 200px;
          .dealer-photo {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
          .photo-overlay {
            display: none;
          }
          .certificated-dealer {
            p {
              font-size: 11px;
              text-align: center;
              margin-right: 5px;
            }
            .icon-certificate {
              margin-right: 5px;
              height: 20px;
              margin-left: 5px;
            }
          }
        }
        .card-information-wrapper {
          margin: 0 0 0 20px;
          padding-bottom: 0;
          border-bottom: 0;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          position: relative;
          height: 100%;
          .card-title {
            font-size: 18px;
            display: flex;
          }
          .social-icons {
            position: absolute;
            justify-content: flex-start;
            bottom: 0;
            top: unset;
            left: 0;
            .icon-wrapper {
              margin: 5px 5px 0 0;
            }
          }
          .card-data {
            margin: 0;
            font-size: 14px;
          }
          .button-secondary {
            position: unset;
            padding: 0 15px;
            margin: 15px 0;
          }
        }
      }
      .opinion-dealer {
        flex-direction: column;
        justify-content: flex-start;
        width: fit-content;
        margin: 0;
        border-bottom: 0;
        .button-secondary {
          width: fit-content;
          font-size: 16px;
          margin-top: 10px;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .opinion {
          .opinion-data {
            display: flex;
            width: fit-content;
            align-items: center;
            margin-top: -10px;
            .percentage {
              font-size: 35px;
              margin-right: 5px;
              margin-bottom: 0;
              color: $brandBlue;
            }
            .opinion-text {
              font-size: 14px;
              text-transform: uppercase;
              margin-bottom: 0;
              color: $brandBlue;
            }
          }
          .rate-star {
            height: 20px;
            width: 20px;
            margin: 0 4px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .main-container {
      max-width: 1240px;
    }
    .desktop {
      display: flex !important;
    }
    .up-btn {
      display: none;
    }
    .result-information {
      font-size: 16px;
      margin: 20px 0 30px;
    }
    .information-tab {
      .information-tab-row {
        max-width: 1240px;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .card-opinion-wrapper {
      display: flex;
      justify-content: space-between;
      .dealer-card {
        width: 800px;
        .dealer-photo-wrapper {
          width: 400px;
          .certificated-dealer {
            bottom: unset;
            top: 0;
            p {
              font-size: 16px;
              text-align: left;
            }
            .icon-certificate {
              margin-right: 20px;
              height: 30px;
            }
          }
          .dealer-photo {
            width: 100%;
            height: 400px;
          }
          .photo-overlay {
            display: none;
          }
        }
        .card-information-wrapper {
          margin-left: 30px;
          padding-left: 10px;
          .card-title {
            font-size: 28px;
            display: flex;
          }
          .info-buttons {
            margin-top: 20px;
          }
        }
      }
      .opinion-dealer {
        flex-direction: column;
        justify-content: flex-start;
        width: fit-content;
        margin: 0;
        border-bottom: 0;
        .button-secondary {
          width: fit-content;
          font-size: 16px;
          margin-top: 10px;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .opinion {
          .opinion-data {
            display: flex;
            width: fit-content;
            align-items: center;
            margin-top: -10px;
            .percentage {
              font-size: 35px;
              margin-right: 5px;
              margin-bottom: 0;
              color: $brandBlue;
            }
            .opinion-text {
              font-size: 14px;
              text-transform: uppercase;
              margin-bottom: 0;
              color: $brandBlue;
            }
          }
          .rate-star {
            height: 20px;
            width: 20px;
            margin: 0 4px;
          }
        }
      }
    }
  }

  .contact-forms {
    display: none;
  }
  .mobile-button {
    margin: 20px auto;
    width: 100%;
    .button-secondary-icon {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .information-form-section {
    margin: 20px 0;
    border-top: 2px solid $brandBlue;
    .about-dealer {
      margin-top: 25px;
      .about-dealer-title {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
      }
      .about-dealer-content {
        font-size: 14px;
        margin: 10px 0;
        height: 100px;
        line-height: 20px;
        overflow: hidden;
        overflow-wrap: break-word;
      }
      .full-content {
        height: fit-content;
      }
      .see-more-wrapper {
        width: 100%;
        margin: 15px auto;
        cursor: pointer;
        .see-more-text-icons {
          font-size: 15px;
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          p {
            margin: 0;
            color: $brandBlue;
          }
          .arrow-blue {
            margin-left: 10px;
          }
        }
      }
      .dealer-information {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .information-data {
          width: 100%;
          margin: 20px 0;
          .till-title {
            color: $brandBlue;
            font-size: 14px;
            margin-bottom: 5px;
            margin-left: 5px;
          }
          .till {
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 6px $shadowColor;
            p {
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .mobile-button {
      display: none;
    }
    .contact-forms {
      display: block;
      .forms-wrapper {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: $mainLight;
        .form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 15px;
          row-gap: 5px;
          .form-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .separator {
              margin: 0 10px;
            }
            .collapse.in {
              display: block;
            }
          }
          input,
          select,
          textarea {
            width: 100%;
          }
          .item-full {
            grid-column: 1/-1;
          }
        }
      }
    }
    .information-form-section {
      border-top: 0;
      .about-dealer {
        padding: 0 20px;
        .about-dealer-content {
          font-size: 14px;
          height: fit-content;
        }
        .see-more-wrapper {
          display: none;
        }
        .dealer-information {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .information-data {
            width: 47%;
            margin: 20px 0;
          }
        }
      }
      .tabs-wrapper {
        width: 80%;
        .tab {
          font-weight: bold;
        }
        .active-tab {
          background: $mainLight;
          font-weight: 700;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .information-form-section {
      display: flex;
      justify-content: space-between;
      .about-dealer {
        width: 55%;
      }
      .contact-forms {
        width: 40%;
        margin-top: -50px;
        .forms-wrapper {
          background: $mainLight;
          .form {
            grid-template-columns: 1fr;
            row-gap: 0;
            .collapse.in {
              display: block;
            }
          }
        }
        .tabs-wrapper {
          width: 100%;
          .tab {
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}

// OPINION SECTION

.opinion-section-wrapper {
  margin: 50px 0;
  .tabs-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: all 0.3s;
    z-index: 10;
    &.sticky {
      background: $mainLight;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
      .tabs-wrapper {
        border-radius: 0 !important;
        .tab {
          border-radius: 0 !important;
        }
      }
    }
  }
  .tabs-content {
    .tabs-wrapper {
      width: 200px;
      .tab {
        img {
          margin-right: 10px;
        }
      }
      .active-tab {
        background: $mainLight;
        font-weight: 700;
      }
    }
  }
  .opinion-section-content {
    padding: 20px 0 0;
    background: $mainLight;
    .switch-content {
      margin: 20px auto;
    }
    .cards-wrapper {
      margin: 0 auto;
    }
    .pagination-wrapper {
      margin: 0 auto;
      padding-bottom: 50px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .opinion-section-wrapper {
    .opinion-section-content {
      .cards-wrapper {
        .opinion-card-wrapper {
          margin: 20px 0;
        }
      }
    }
  }
}

// MODAL
.modal-content {
  width: 80%;
  min-width: 360px;
  .form {
    .collapse.in {
      display: block;
    }
  }
  .switcher-form-type {
    display: flex;
    width: fit-content;
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    .switch-view-tabs {
      background-color: $backgroundWhite;
      width: 90%;
      margin: 20px 5%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .switch-view-tab-left {
      width: 50%;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border: 1px solid $brandOrange;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    .switch-view-tab-right {
      width: 50%;
      height: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid $brandOrange;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    .switch-view-active-tab {
      background-color: $brandOrange;
      color: $textLight;
    }
  }
}
