.custom-control-label {
  cursor: pointer;
}

.custom-control-label::after {
  top: 4px;
  left: -24px;
  width: 20px;
  height: 20px;
}

.custom-control-label::before {
  border: 2px solid $mainDark;
  width: 20px;
  height: 20px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $brandOrange;
  border-color: $brandOrange;
}
