.dealer-account {
#salon-content {
  display: none;
}

#dealeraccount–overall {
  display: none;
}

.container {
  padding: 0;
}
#availability {
  .input-label-small {
    label {
      margin-bottom: 0;
    }
  }
}

.disabled-switch {
  border-color: $disabledColor !important;
  color: $disabledColor !important;
  box-shadow: none !important;
}

.bold-text {
  color: $mainDark !important;
  font-weight: bold !important;
}

.dealeraccount–overall {
  .form {
    padding: 0 !important;
  }
  .error {
    font-size: 12px;
    margin-top: -19px;

    margin-bottom: 1px;
  }
  .error-space {
    margin-left: 35%;
    @media only screen and (max-width: 576px) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
  .exception-value {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .trash-icon {
    cursor: pointer;
  }
  .sub-tab {
    .button-secondary {
      @media only screen and (max-width: 615px) {
        display: none;
      }
    }

    .container {
      background-color: $backgroundWhite;
      padding: 15px 20px;

      @media only screen and (max-width: 576px) {
        padding: 15px 10px;
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .tabs-typo {
      .tabs {
        @media only screen and (max-width: 576px) {
          margin: 0;
        }
      }
    }
  }

  .content {
    @media only screen and (max-width: 576px) {
      margin-bottom: 70px;
    }
    .container {
      background-color: $backgroundWhite;
    }
  }

  .content-element {
    margin-bottom: 30px;

    @media only screen and (max-width: 576px) {
      margin-bottom: 20px;
    }

    .input-label {
      border-top: 1px solid rgba($mainDark, 0.1);
      padding-top: 18px;
      margin-bottom: 0;

      @media only screen and (max-width: 576px) {
        padding-top: 15px;
      }
    }

    .element-header {
      // border-top: 1px solid rgba($mainDark, 0.1);
      padding-top: 18px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .button-arrow {
        span {
          color: $brandBlue;
        }
      }
      @media only screen and (max-width: 576px) {
        padding-top: 15px;
        margin-bottom: 15px;
      }

      p {
        margin: 0;

        @media only screen and (max-width: 576px) {
          font-size: 14px;
        }
      }
    }

    .badges-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      &.collapse {
        overflow: hidden;
        height: 0;
      }

      &.show {
         height: auto;
      }
    }

    .box-badges {
      border-radius: 15px;
      box-shadow: 0px 0px 16px rgba($mainDark, 0.1);
      padding: 20px 30px;

      @media only screen and (max-width: 576px) {
        padding: 15px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($mainDark, 0.1);
        @media only screen and (max-width: 576px) {
          padding-bottom: 10px;
        }
      }

      > .active,
      .inactive {
        margin-top: 18px;
        margin-bottom: 18px;
        width: 100%;
      }

      > .active {
        @media only screen and (max-width: 576px) {
          margin-top: 0;
        }
      }

      .badge-icon,
      .badge {
        margin-bottom: 7px;
        margin-right: 7px;
      }

      .bottom {
        text-align: right;
        margin-top: 15px;
      }
    }
  }
}

.dealeraccount–workers {
  > .content {
    .button-arrow {
      span {
        color: $brandBlue;
      }
    }
    .sorting-table {
      display: none;
      align-items: center;

      p {
        margin-bottom: 0;
        margin-right: 20px;
        font-size: 14px;

        @media only screen and (max-width: 600px) {
          font-size: 12px;
        }
      }

      .sorting-dropdown {
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }

      @media only screen and (max-width: 1200px) {
        display: flex;
      }
    }

    .sorting-row {
      display: flex;

      @media only screen and (max-width: 1200px) {
        display: none;
      }

      .sorting-element {
        display: flex;

        &.on {
          color: $brandOrange;
          font-weight: 500;
          .sort-item {
            display: none;
          }
          .sort-item-active {
            display: block;
          }
        }
        &.off {
          .sort-item {
            display: block;
          }
          .sort-item-active {
            display: none;
          }
        }
        p {
          font-size: 12px;
          margin-right: 15px;
          margin-bottom: 0;
        }

        &:first-of-type {
          width: 23%;
        }

        &:nth-of-type(2) {
          width: 22%;
        }

        &:nth-of-type(3) {
          width: 15%;
        }

        &:nth-of-type(4) {
          width: 15%;
        }

        &:nth-of-type(5) {
          width: 13%;
        }

        &:nth-of-type(6) {
          width: 18%;
        }
        img {
          cursor: pointer;
        }
      }
    }

    .table-worker {
      margin-top: 30px;

      .row-wrapper {
        display: flex;
        padding: 20px 15px;
        box-shadow: 0px 0px 16px rgba($fullBlack, 0.1);
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;

        @media only screen and (max-width: 1200px) {
          padding: 20px 15px 20px 5px;
        }

        .cell-wrapper {
          @media only screen and (max-width: 1200px) {
            flex-direction: column;
            order: 2;
          }

          &:first-of-type {
            width: 48%;

            @media only screen and (max-width: 991px) {
              width: 40%;
            }

            @media only screen and (max-width: 680px) {
              width: calc(100% - 195px);
            }

            .name,
            .work {
              width: 48%;
              margin-right: 10px;
              overflow: hidden;

              @media only screen and (max-width: 1200px) {
                width: calc(100% - 10px);
              }
            }

            .name {
              @media only screen and (max-width: 1200px) {
                margin-bottom: 5px;
              }
            }

            .work {
              @media only screen and (max-width: 1200px) {
                font-size: 10px;
                opacity: 0.6;
              }
            }
          }

          &:nth-of-type(2) {
            width: 33%;

            @media only screen and (max-width: 1200px) {
              width: 35%;
            }

            @media only screen and (max-width: 680px) {
              width: 90px;
            }

            .rate,
            .meassage {
              width: 50%;
              display: flex;

              .mobile-icon {
                display: none;
              }

              @media only screen and (max-width: 1200px) {
                width: calc(100% - 10px);

                .mobile-icon {
                  display: block;
                  margin-right: 14px;
                }
              }

              @media only screen and (max-width: 680px) {
                width: 90px;
              }

              button {
                @media only screen and (max-width: 680px) {
                  display: none;
                }
              }
            }

            .rate {
              @media only screen and (max-width: 1200px) {
                margin-bottom: 5px;
              }
            }
          }
        }

        .date,
        .permissions {
          @media only screen and (max-width: 1200px) {
            order: 2;
          }

          width: calc(15% - 10px);

          img {
            &:first-of-type {
              margin-right: 15px;
            }
          }
        }

        .permissions {
          @media only screen and (max-width: 680px) {
            width: 60px;
          }
        }

        .date {
          @media only screen and (max-width: 991px) {
            width: 110px;
          }

          @media only screen and (max-width: 680px) {
            display: none;
          }
        }

        .options {
          width: 40px;
          margin-left: auto;
          justify-content: center;

          @media only screen and (max-width: 1200px) {
            order: 1;
            margin-right: 5px;
          }
        }

        > div {
          display: flex;
          align-items: center;
          overflow: hidden;

          .button-arrow {
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }

      p {
        font-size: 12px;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
  }
}

.options-box {
  position: absolute;
  background-color: $backgroundWhite;
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 15px;
  box-shadow: 0px 10px 20px rgba($fullBlack, 0.1);
  right: 0;
  top: 0;
  z-index: 2;

  > div {
    width: 100%;
  }

  .box-header {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($fullBlack, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .title {
    padding-top: 10px;
    font-size: 14px;
    margin-bottom: 0;
    margin-bottom: 14px;
  }

  .box-premissions {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($fullBlack, 0.1);

    .custom-switch {
      width: 100%;
      label {
        padding-right: 10px;
        font-size: 12px;
        font-weight: 500;
      }
      .custom-control-label::before {
        height: 16px;
        width: 1.75rem;
      }
      .custom-control-label::after {
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
      }
    }
  }

  .box-work {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($fullBlack, 0.1);
  }

  .box-other {
    display: flex;
    padding-top: 10px;
    button,
    a {
      padding: 10px 15px;
      width: 50%;
      min-width: 160px;
      font-size: 12px;
      color: $mainDark;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      img {
        margin-right: 10px;
        height: 20px;
        width: auto;
        vertical-align: middle;
      }
      &:hover {
        background: $mainLight;
      }
    }

    .button-delate {
      width: 50%;
      font-size: 12px;
      color: $mainDark;
      img {
        margin-right: 10px;
        height: 20px;
        width: auto;
        vertical-align: middle;
      }
    }
  }
  .button-save {
    margin-top: 10px;
    padding: 10px 15px;
    background: none;
    border: 1px solid $brandOrange;
    color: $brandOrange;
    font-weight: 500;
    border-radius: 5px;
    &:hover {
      background: $brandOrange;
      color: white;
    }
  }
}

  .content-title-tooltop {
    display: block !important;
    position: relative;
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-size: 16px;
      @media only screen and (max-width: 576px) {
        font-size: 12px;
      }
    }
    .img-info-wrapper {
        max-width: 360px;
        #photo-error {
            font-size: 16px;
            @media only screen and (max-width: 576px) {
              font-size: 12px;
            }
            color: $alertError;
            margin-top: 15px;
            display: none;
        }
    }
  }

  .box-tooltip {
    position: absolute;
    top: -25px;
    left: 140px;
    @media only screen and (max-width: 570px) {
      top: -4px;
      left: 120px;
    }
  }

  .img-box-wrapper {
    &:first-of-type {
      margin-bottom: 60px;
    }
    &:nth-of-type(2) {
      .box-tooltip {
        left: 135px;
        @media only screen and (max-width: 570px) {
          left: 104px;
        }
      }
      .img-box-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width: 1200px) {
          justify-content: flex-start;
        }
        .add-img,
        .img-added {
          &:first-of-type,
          &:nth-of-type(2),
          &:nth-of-type(3) {
            margin-bottom: 30px;
          }
          @media only screen and (max-width: 670px) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

.time-picker-label {
  .pickers-opening-hours {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    .range-time-picker {
      width: fit-content;
      .time-pickers {
        margin: 0;
        input.small-time-picker {
          padding: 5px;
          font-size: 12px;
          width: 80px;
          text-align: center;
        }
      }
      .separator {
        margin: 0 5px;
      }
    }
    .switch-general {
      padding: 0;
      width: inherit;
      margin-left: 20px;
      min-width: 110px;
      max-width: 130px;
      label {
        font-size: 14px;
        width: 100%;
        color: $notActiveText;
      }
      .custom-control-label::before {
        height: 16px;
        top: 8px;
        right: 0;
        width: 1.75rem;
      }
      .custom-control-label::after {
        top: 10px;
        right: 14px;
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
      }
    }
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .time-picker-label {
    .pickers-opening-hours {
      .range-time-picker {
        .time-pickers {
          input.small-time-picker {
            padding: 3px;
            font-size: 11px;
            width: 80px;
          }
        }

        .separator {
          margin: 0 2px;
        }
      }
      .switch-general {
        margin-left: 25px;
        max-width: 100px;
        label {
          font-size: 12px;
        }
        .custom-control-label::before {
          top: 6px;
          width: 1.75rem;
        }
        .custom-control-label::after {
          top: 8px;
          width: calc(1rem - 4px);
          height: calc(1rem - 4px);
        }
      }
    }
  }
}
}
