@import "../../../../global/homepage/scss/colors.scss";

.active-card {
    border: 2px solid $brandBlue;
}

.dealership-card-small-wrapper {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 16px $shadowColor;
    cursor: pointer;
    width: 600px;
    .dealership-card-small-info {
        width: 340px;
        padding: 18px 30px;
        .star-generate-wrapper {
            margin: 10px 0;
        }
        .title {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        .dealer-type {
            margin-top: 0;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        .dealer-address {
            margin-top: 5px;
            font-size: 12px;
            margin-bottom: 0;
        }
        .dealer-marks {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .dealer-mark {
                margin-right: 10px;
                margin-top: 20px;
            }
        }
        .footer {
            .arrow-blue {
                padding: 3px;
            }
            span {
                margin-right: 5px;
                color: $brandBlue;
                font-size: 15px;
            }
            a {
                text-decoration: none;
            }
        }
    }
    .dealership-card-small-workers {
        width: 260px;
        display: flex;
        flex-direction: column;
        .workers-header {
            width: 100%;
            background-color: $brandOrange;
            color: $textLight;
            font-size: 14px;
            text-transform: uppercase;
            padding: 5px 0;
            text-align: center;

        }
        .workers-content {
            background-color: $mainLight;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 30px;
            flex: 1;

            .worker-informations {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: calc(100% / 3);
                .workers-header-name-wrapper {
                    font-size: 14px;
                    .icon-certificate {
                        height: 20px;
                        margin-bottom: 3px;
                        margin-right: 3px;
                    }
                }

            }
        }
    }
}
