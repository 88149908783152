.dealer-account {
.select-default {
    border: 1px solid $selectBorderColor;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 10px;
    &.sorting-dropdown {
        font-size: 16px;
        height: 50px;
        width: 270px;
    }
    &:focus {
        outline: none;
    }
}

.input-custom-date {
    display: inline-block;
    border: 1px solid $selectBorderColor;
    padding: 0;
    height: 50px;
    font-size: 14px;
    border-radius: 10px;
    &:before {
        content: url(@/icons/icon-calendar.svg);
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
    }
    .date-input {
        cursor: pointer;
        border: none;
        background: none;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 38px;
        outline: none;
    }
}

.form-group {
    input,
    select,
    textarea {
        padding: 20px 10px;
        border-radius: 10px;
        border: 1px solid $mainDark;
        font-size: 16px;

        @media only screen and (max-width: 576px) {
            padding: 15px;
            font-size: 12px;
        }

        &:active,
        &:focus {
            border-color: $brandOrange;
            box-shadow: none;
        }

        &::placeholder {
            opacity: 0.5;

            @media only screen and (max-width: 576px) {
                font-size: 12px;
            }
        }
    }
    select {
        padding: 5px 30px;
        line-height: 20px;
        height: 42px;
    }
}

.messages {
    display: none;
}

.input-label {
    display: flex;
    flex-direction: column;

    label {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;

        @media only screen and (max-width: 576px) {
            font-size: 14px;
        }
    }

    input,
    textarea {
        border: none;
        box-shadow: 0px 0px 16px rgba($fullBlack, 0.1);
    }
}

.input-label-small {
    display: flex;
    align-items: center;

    .second-col-input {
      padding-left: 15px;
    }

    @media only screen and (max-width: 576px) {
        flex-wrap: wrap;
    }

    label {
        width: 50%;
        font-size: 14px;

        @media only screen and (max-width: 576px) {
            font-size: 12px;
            width: 100%;
        }
    }

    input,
    textarea {
        border: none;
        box-shadow: 0px 0px 16px rgba($fullBlack, 0.1);
    }
}

.card-wrapper {
    position: relative;

    .card-content {
        transition: all 0.3s;

        label {
            margin: 0;
            display: flex;
            align-items: center;
        }

        * {
            cursor: pointer;
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            margin-left: 13px;
        }

        .images {
            img {
                height: 31px;
                width: auto;
            }

            .image-check {
                opacity: 0;
                display: none;
            }

            .image-uncheck {
                opacity: 1;
                display: block;
            }
        }
    }

    .c-card {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;

        ~ .card-content {
            transition: all 500ms ease-out;
        }

        &:checked ~ .card-content {
            p {
                color: $brandOrange;
            }

            .images {
                .image-check {
                    opacity: 1;
                    display: block;
                }

                .image-uncheck {
                    opacity: 0;
                    display: none;
                }
            }

            .card-title {
                color: white;
            }

            .text {
                .h2 {
                    color: white;
                }
            }

            .checkbox {
                padding-left: 50px;
                position: relative;

                &::before {
                    border-color: white;
                }

                &::after {
                    opacity: 1;
                }

                .text-check {
                    opacity: 1;
                    display: inline;
                    color: white;
                }

                .text-uncheck {
                    opacity: 0;
                    display: none;
                }
            }

            &:checked:hover ~ .card-content {
            }
        }
    }
}

.custom-control-label::after {
    top: 4px;
    left: -24px;
    width: 20px;
    height: 20px;
}

.custom-control-label::before {
    border: 2px solid $mainDark;
    width: 20px;
    height: 20px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $brandOrange;
    border-color: $brandOrange;
}

.custom-radio {
    .custom-control-label::after {
        display: none;
    }

    .custom-control-label::before {
        border: 2px solid $mainDark;
        width: 20px;
        height: 20px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $brandOrange;
        border-color: $brandOrange;
    }

    .custom-control-input:checked ~ .custom-control-label {
        color: $brandOrange;
    }
}

.custom-switch {
    padding-right: 4.1rem;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    @media only screen and (max-width: 600px) {
        max-width: fit-content;
    }
}

.custom-switch .custom-control-label::after {
    right: -1.5rem;
    left: auto;
    top: 2px;
    background-color: $backgroundWhite;
}

.custom-switch .custom-control-label::before {
    right: -2.35rem;
    top: 0;
    left: auto;
    border-color: $mainDark;
    background-color: $mainDark;

    @media only screen and (max-width: 1200px) {
        height: 14px;
    }
}
}
