.dealer-account {
.dealer-account-main {
  flex: 1 0 auto;
  background-color: $mainLight;
  padding-top: 100px;
  padding-bottom: 100px;
  @media only screen and (max-width: 991px) {
    padding: 50px 0;
    .content {
      .container {
        max-width: 100%;
      }
    }
    .main-tab.container {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 30px 0;
  }
  .sub-tab {
    @media only screen and (max-width: 615px) {
      .button-wrapper-profile {
        width: 100vw;
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $backgroundWhite;
      }
    }
    .container {
      background-color: $backgroundWhite;
      padding: 15px 20px;
      border-radius: 0px 15px 0px 0px;

      @media only screen and (max-width: 576px) {
        padding: 15px 10px;
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .tabs-typo {
      .tabs {
        @media only screen and (max-width: 576px) {
          margin: 0;
        }
      }
    }
  }

  section.content {
    .container {
      background-color: $backgroundWhite;
      border-radius: 0px 0px 15px 15px;
      padding-bottom: 40px;
      padding-top: 20px;
      padding: 30px;
      @media only screen and (max-width: 1200px) {
        padding: 15px;
      }
      .content-title {
        padding-top: 15px;
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba($fullBlack, 0.1);
        h1 {
          font-size: 18px;
          font-weight: 500;
          @media only screen and (max-width: 576px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.container-loader {
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  padding-bottom: 100px;
  padding-top: 100px;
  div {
    margin: 0 auto;
  }
}

.container-loader {
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  padding-bottom: 100px;
  padding-top: 100px;
  div {
    margin: 0 auto;
  }
}

@mixin information-data {
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  padding-bottom: 100px;
  padding-top: 100px;
  font-size: 24px;
  font-weight: bold;
  display: none;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
}

.information-no-data {
  @include information-data;
  color: $brandOrange;
}

.error-messages-api {
  @include information-data;
  color: $alertError;
}

.element-header {
  border-top: 1px solid rgba($mainDark, 0.1);
  padding-top: 18px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 576px) {
    padding-top: 15px;
    margin-bottom: 15px;
  }

  p {
    margin: 0;

    @media only screen and (max-width: 576px) {
      font-size: 14px;
    }
  }

  .element-title {
    white-space: nowrap;
    font-weight: 500;
  }

  .box-tooltip {
    position: absolute;
    top: -15px;
    left: 215px;
    position: absolute;
    z-index: 2;
    @media only screen and (max-width: 576px) {
      top: 0px;
      left: 150px;
    }
    .tooltip-info {
      @media only screen and (max-width: 366px) {
        width: 140px;
      }
    }
  }
}

#google-form {
  display: none;
}
}