.header-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 100%;
  padding: 20px 0;
  display: flex;
  z-index: 10;

  .header-logo {
    background: rgb(255, 255, 255);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    padding: 5px 5px 5px 5px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .header-button-wrapper {
    justify-content: flex-end;
    display: none;
    .my-page-link {
      display: none;
    }
  }
  .button-primary {
    margin-right: 10px;
    white-space: nowrap;
  }
  .nav-mobile {
    float: right;
    margin: 5px 20px 5px auto;
    &.disabled {
      pointer-events: none;
    }
  }
  .collapse-menu {
    width: 100%;
    top: 75px;
    position: absolute;
    z-index: 20;
    background: white;
    &.in {
      display: block;
    }
    .header-button-wrapper {
      display: flex;
      justify-content: center;
      margin: 10px 0;

      @media only screen and (max-width: 449px) {
        flex-direction: column;
        align-items: center;
        .button-primary {
          margin: 1px;
        }
        .button-secondary {
          margin: 1px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1093px) {
  .header-wrapper {
    .header-button-wrapper {
      display: flex;
    }
    .nav-mobile {
      display: none;
    }

    .button-primary {
      margin-right: 10px;
      padding: 0px 25px 0px 25px;
    }
    .button-secondary {
      padding: 0px 25px 0px 25px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .header-wrapper {
    .button-primary {
      margin-right: 10px;
      padding: 0px 25px 0px 25px;
    }
    .button-secondary {
      padding: 0px 25px 0px 25px;
    }
  }
}