@import "../../../../global/homepage/scss/colors.scss";
.footer-wrapper {
  margin: 20px 0;
  .footer-copyright-row {
    margin: 10px auto 40px auto;
    .footer-copyright {
      text-align: center;
      font-size: 12px;
      width: 100%;
      color: $mainDark;
    }
  }
  .mobile-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-social-icon {
      margin: 0 10px 0 0;
      width: 26px;
      height: 26px;
    }
  }
  .main-footer-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px;
    .footer-column {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin-top: 20px;
      white-space: nowrap;
      width: 50%;
      .footer-logo {
        margin-right: auto;
        margin-bottom: 20px;
        height: 25px;
        cursor: pointer;
      }
      .footer-link {
        margin-bottom: 10px;
        color: $mainDark;
        font-size: 12px;
        white-space: pre-wrap;
      }
      .footer-column-title {
        margin-bottom: 14px;
        color: $mainDark;
        display: none;
      }
      .footer-social-media {
        display: none;
        a,
        a:hover,
        a:focus,
        a:active {
          text-decoration: none;
        }
        .footer-social-icon {
          margin-right: 5px;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer-wrapper {
    .footer-copyright-row {
      margin-top: 20px;
    }
    .mobile-icons {
      display: none;
      width: 22px;
      height: 22px;
    }
    .main-footer-row {
      .footer-column {
        width: 25%;
        .footer-logo {
          height: 50px;
        }
        .footer-link {
          margin-bottom: 8px;
          font-size: 16px;
        }
        .footer-column-title {
          display: block;
        }
        .footer-social-media {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .footer-social-icon {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .footer-wrapper {
    max-width: 1240px;
    margin: 30px auto;
    .footer-copyright-row {
      margin-top: 40px;
    }
    .mobile-icons {
      display: none;
    }
    .main-footer-row {
      .footer-column {
        width: 25%;
        .footer-link {
          margin-bottom: 8px;
          font-size: 16px;
        }
        .footer-column-title {
          display: block;
        }
        .footer-social-media {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .footer-social-icon {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
