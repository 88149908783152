.user-account {
.user-account–option {
  .form-group-input-wrapper {
    .input-main {
      width: 100%;
    }
  }
  .options-content-box {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    .box-info {
      width: 340px;

      #collapseOptionsInfoWrapper{
        padding:15px;
        p {
          font-size: 14px;
        }
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    .option-content-wrapper {
      width: calc(100% - 380px);
      margin-left: auto;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }
      .options-form {
        padding: 20px 0;
      }
    }
  }
  .tabs-typo {
    p {
      &::first-letter {
        @media only screen and (max-width: 600px) {
          text-transform: uppercase;
        }
      }
    }
  }
}

#user-account–option {
  display: none;
}

@media only screen and (min-width: 1200px) {
  #collapseOptionsInfoWrapper {
    .box-header {
      pointer-events: none;
    }
  }
}

#login-form {
  display: none;
}

#email-form {
  display: none;
}

#passwords-form {
  display: none;
}

#form-error {
  color: $alertError;
  font-size: 16px;
  margin-bottom: 5px;
}
}