@import "../../../global/homepage/scss/colors.scss";
@import "../../../global/homepage/scss/_mixins.scss";

@import "./forms";
@import "./cards-boxes";
@import "./core-view";
@import "./messages";
@import "./modals";
@import "./profile";
@import "./opinions";
@import "./tables";
@import "./tabs";

body.worker-account {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header-wrapper {
    .header-button-wrapper {
      .my-page-link {
        display: block;
      }
      .my-account-link {
        display: none;
      }
    }
  }
}