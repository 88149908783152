.thanks {
  &--container {
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;

    &--section {
      min-height: 700px;
      max-width: 100vw;
      background: url("@/homepage/BG-img.svg") no-repeat center top;
      background-size: cover;
      padding-top: 150px;

      @media only screen and (min-width: 768px) {
        padding-top: 150px;
        min-height: 700px;
        margin-bottom: 150px;
      }

      &--wrapper {
        margin: 0 auto 10px;
        background: #ffff;
        border-radius: 10px;
        overflow: hidden;
        padding: 30px;

        @media only screen and (min-width: 768px) {
          width: 700px;
          z-index: 1;
          position: relative;
        }

        &--graphic {
          height: 120px;

          @media only screen and (max-width: 934px) {
            h2 {
              font-size: 2rem;
            }
          }

          @media only screen and (max-width: 450px) {
            height: 85px;

            h2 {
              font-size: 20px;
            }
          }

          &--img {
            height: 80px;

            @media only screen and (max-width: 450px) {
              height: 60px;
            }
          }
        }
      }
    }

    &--car-img {
      position: absolute;
      height: 50px;
      left: -25px;
      bottom: 20px;
      display: none;

      @media only screen and (min-width: 768px) {
        left: -100px;
        height: 200px;
        bottom: 10px;
        display: block;
      }

      @media only screen and (min-width: 1200px) {
        left: -350px;
        height: 300px;
        bottom: 50px;
      }
    }

    &--person-img {
      position: absolute;
      right: 0;
      height: 150px;
      bottom: -65px;
      display: none;

      @media only screen and (min-width: 768px) {
        right: 0;
        height: 200px;
        bottom: 0;
        display: block;
      }

      @media only screen and (min-width: 1200px) {
        right: -20px;
        height: 400px;
        bottom: 0;
        display: block;
      }
    }
  }
}
