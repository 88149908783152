@import "../../../../global/homepage/scss/colors.scss";

@mixin select-option {
  border: 1px solid;
  height: 35px;
  width: 100%;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  transition: border-radius 0.5s;
}

#error-handler {
  color: $alertError;
  font-size: 12px;
  margin-top: 20px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  display: none;
}

.without-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search-dealer-form-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: $backgroundWhite;
  overflow: visible;
  margin-bottom: 15px;
  .search-dealer-tabs-wrapper {
    display: flex;
    background-color: $mainDark;
    height: 54px;
    .tab {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      color: $mainLight;
      justify-content: center;
      align-items: center;
    }
    .active-tab {
      background-color: $backgroundWhite;
      color: $mainDark;
    }
  }
  .search-dealer-form {
    width: 80%;
    margin: 26px auto 0 auto;
    .search-dealer-options {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .search-wrapper {
        width: fit-content;
        width: 100%;
        margin: 5px auto;
        position: relative;
        .search-options-mark {
          @include select-option;
          border-color: $mainDark;
          color: $mainDark;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          #mark-input-name-input, #mark-input-name-input:focus {
            border-style: none;
            outline: none;
          }
          #mark-input-name, #mark-input-name:focus {
            border-style: none;
            outline: none;
            display: none;
          }
        }
        .search-options-place {
          @include select-option;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          #localization-input-name-input, #localization-input-name-input:focus {
            border-style: none;
            outline: none;
          }
          #localization-input-name, #localization-input-name:focus {
            border-style: none;
            outline: none;
            display: none;
          }
        }
        .collapse-mark {
          border: 1px solid $mainDark;
        }
        .collapse-place {
          border: 1px solid $mainDark;
        }
        .collapse-search {
          position: absolute;
          border-top: 0;
          padding: 0;
          width: 100%;
          max-height: 200px;
          overflow: auto;
          background: $backgroundWhite;
          z-index: 2;
          &.in {
            display: block;
          }
          .custom-control {
            margin: 4px 10px;
            min-height: 24px;
            &:first-of-type {
              margin-top: 10px;
            }
            &:last-of-type {
              margin-bottom: 24px;
            }
          }
          .custom-control-label {
            margin-top: 5px;
            span {
              font-size: 14px;
              margin-left: 2px;
              position: absolute;
              top: 4px;
              white-space: nowrap;
            }
          }
        }
        div[class^="search-options"] {
          &.in + .collapse-search {
            z-index: 3;
          }
        }
      }
    }
  }
  .search-dealer-btn {
    margin-top: 20px;
    height: 50px;
  }
  .checkbox-certificated-dealers {
    margin-top: 30px;
    .custom-control-label {
      cursor: pointer;
    }
    span {
      font-size: 16px;
      margin-left: 15px;
      position: absolute;
      top: 2px;
      white-space: nowrap;
    }
  }
}

@media only screen and (min-width: 768px) {
  #error-handler {
    bottom: 30px;
    left: 70px;
  }
  .search-dealer-form-wrapper {
    .search-dealer-form {
      width: 80%;
      margin: 38px auto 0 auto;
      .search-dealer-options {
        justify-content: space-between;
        flex-direction: row;
        .search-wrapper {
          width: 46%;
          margin: 0;
        }
      }
    }
    .search-dealer-btn {
      margin-top: 23px;
      height: 50px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .search-dealer-form-wrapper {
    .search-dealer-form {
      .search-dealer-options {
        .search-wrapper {
          .collapse-search {
            .custom-control-label {
              span {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .search-dealer-btn {
      margin-top: 25px;
      height: 50px;
    }
  }
} ;
