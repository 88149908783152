@mixin hover-focus {

    &:hover,
    &:focus {
        @content;
    }
}


@mixin center($position) {
    position: absolute;

    @if $position=="vertical" {
        top: 50%;
        transform: translateY(-50%);
    }

    @else if $position=="horizontal" {
        left: 50%;
        transform: translate(-50%);
    }

    @else if $position=="both" {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin fa-icon($icon) {
    content: $icon;
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-weight: 900;
}



@mixin media($size, $type: max, $dimension: width) {
    @if $type=='max' {
        @media all and (#{$type}-#{$dimension}: $size - 1) {
            @content;
        }
    }

    @if $type=='min' {
        @media all and (#{$type}-#{$dimension}: $size) {
            @content;
        }
    }
}