.user-account {
button:focus {
  outline: 0 !important;
}
.custom-control-label {
  padding-top: 2px;
}
#user-id {
  display: none;
}

.container-loader {
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  padding-bottom: 100px;
  padding-top: 100px;
  div {
    margin: 0 auto;
  }
}

@mixin information-data {
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  padding-bottom: 100px;
  padding-top: 100px;
  font-size: 24px;
  font-weight: bold;
  display: none;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
}

.information-no-data {
  @include information-data;
  color: $brandOrange;
}

.error-messages-api {
  @include information-data;
  color: $alertError;
}
}
