/* stylelint-disable */
.custom-worker-checkbox {
  padding: 5px 0;

  .form-check-input {
    display: none;

    ~ .form-check-label {
      font-size: 14px;

      &::before {
        height: 16px;
        width: 16px;
        content: "";
        display: block;
        margin-right: 10px;
        margin-top: 3px;
        float: left;
        border: 2px solid #354052;
        border-radius: 0.25rem;
        transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
    &:checked ~ .form-check-label::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 aria-hidden=%27true%27 focusable=%27false%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M6 10l-2 2l6 6L20 8l-2-2l-8 8z%27 fill=%27%23fff%27/%3E%3C/svg%3E") !important;
      background-color: #f6a335;
      border-color: #f6a335;
      content: "";
      display: block;
      float: left;
    }
  }
}

.custom-dropdown-checkbox {
  padding: 5px 0;

  .form-check-input {
    display: none;

    ~ .form-check-label::before {
      height: 20px;
      width: 20px;
      content: "";
      display: block;
      margin-right: 10px;
      margin-top: 3px;
      float: left;
      border: 2px solid #354052;
      border-radius: 0.25rem;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:checked ~ .form-check-label::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 aria-hidden=%27true%27 focusable=%27false%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M6 10l-2 2l6 6L20 8l-2-2l-8 8z%27 fill=%27%23fff%27/%3E%3C/svg%3E") !important;
      background-color: #f6a335;
      border-color: #f6a335;
      content: "";
      display: block;
      float: left;
    }
  }
}
/* stylelint-enable */
