@import "../../../../global/homepage/scss/colors.scss";

.active-thumb,
.active-checkbox {
  p {
    color: $brandOrange;
  }
}
#correct-sent-opinion {
  display: none;
  border-top: 1px solid #000;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  p {
    color: $mainDark;
  }
  button {
    width: 200px;
    background: $brandBlue;
    color: $buttonTextColor;
  }
  h4 {
    padding-top: 20px;
    a {
      color: #f6a335;
    }
  }
}
#submit-button {
  margin: 20px 0;
}
.feedback-wrapper-worker {
  margin-top: 90px;
  max-width: 100vw;
  overflow: hidden;
  .main-container {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 414px;
  }
  .star-wrapper {
    margin: 40px 0 0 20px;
    display: flex;
    img {
      margin: 0 3px;
      height: 30px;
      cursor: pointer;
    }
  }

  .feedback-data-section {
    background: $mainLight;
    width: 100vw;
    .feedback-data-wrapper {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      height: 60px;
      align-items: center;
      p {
        margin: 0 10px 0 0;
        font-size: 12px;
        white-space: nowrap;
        &:after {
          margin-left: 10px;
          content: "|";
        }
        &:last-child {
          &:after {
            content: "";
          }
        }
      }
    }
  }
  .feedback-patch-section {
    font-size: 12px;
    margin: 30px auto;
    img {
      margin: 0 5px 3px 0;
    }
    a {
      color: $mainDark;
    }
  }
  .feedback-opinions-section {
    .user-data {
      margin: 0 auto;
      .title {
        margin-bottom: 5px;
        font-size: 24px;
      }
      .secondary-title {
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
      }
      .describe-section {
        border-top: 1px solid $shadowColor;
        padding-top: 10px;
        margin-top: 20px;
        font-weight: bold;
      }
    }

    .set-opinion {
      display: flex;
      flex-direction: column;
      .cards-opinion {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 20px;
        row-gap: 20px;
        margin: 20px 0 50px;
        .error {
          margin: 15px;
        }
        .opinion-item {
          box-shadow: 0px 6px 12px $shadowColor;
          border-radius: 15px;
          .opinion-item-title {
            font-size: 16px;
            font-weight: 600;
            margin: 20px;
          }
          .star-wrapper {
            margin: 20px;
          }
        }
        .recommendation-opinion {
          .thumbs-wrapper {
            .thumb-element {
              margin: 20px 30px 20px;
              display: flex;
              align-items: center;
              cursor: pointer;
              &.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
              p {
                margin: 0 0 0 25px;
              }
            }
          }
        }
        .meeting-type {
          .radio-buttons {
            padding: 10px 20px;
          }
        }
      }
      .opinion-text {
        padding: 0;
        margin: 0 auto;
        .opinion-text {
          width: 100%;
        }
        .opinion-text-title {
          border-top: 1px solid $shadowColor;
          padding-top: 10px;
          margin: 20px 0 35px 0;
          font-weight: bold;
        }
        .title-opinion {
          input {
            width: 100%;
          }
        }
        .message-opinion {
          textarea {
            width: 100%;
            height: 300px;
          }
        }
        .button-wrapper {
          #add-photo {
            display: none;
          }
        }
        #photo-error {
          font-size: 16px;
          color: $alertError;
          margin-top: 15px;
          display: none;
        }
        #user-photo-wrapper {
          display: none;
          #user-photo {
            margin: 20px auto;
            border-radius: 10px;
            overflow: hidden;
            width: 200px;
          }
          .remove-photo-wrapper {
            cursor: pointer;
            display: flex;
            align-items: center;
            span {
              margin-left: 20px;
            }
          }
        }
      }
      .button-wrapper-submit {
        background: $backgroundWhite;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
//         position: fixed;
        z-index: 1000;
        bottom: 0;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .main-container {
      padding: 0 20px;
      max-width: 808px;
    }
    .feedback-data-section {
      .feedback-data-wrapper {
        p {
          font-size: 16px;
        }
      }
    }
    .feedback-patch-section {
      font-size: 16px;
    }
    .feedback-opinions-section {
      .user-data {
        .title {
          margin-bottom: 10px;
          font-size: 30px;
        }
        .secondary-title {
          font-size: 24px;
        }
        .describe-section {
          padding-top: 20px;
          margin-top: 30px;
        }
      }
      .set-opinion {
        .cards-opinion {
          grid-template-columns: repeat(2, 1fr);
          .general-opinion {
            display: flex;
            justify-items: center;
            align-items: center;
            grid-column: 1 / -1;
            .opinion-item-title {
              width: calc(50% - 20px);
            }
            .star-wrapper {
              margin: 20px 20px 20px 0;
            }
          }
          .opinion-item {
            .opinion-item-title {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .main-container {
      max-width: 1240px;
    }
    .feedback-opinions-section {
      .user-data {
        .title {
          margin-bottom: 10px;
          font-size: 30px;
        }
        .secondary-title {
          font-size: 24px;
        }
        .describe-section {
          padding-top: 20px;
          margin-top: 30px;
        }
      }
      .set-opinion {
        .cards-opinion {
          grid-template-columns: repeat(3, 1fr);
          .general-opinion {
            grid-column: initial;
            display: block;
            .opinion-item-title {
              width: auto;
            }
            .star-wrapper {
              margin: 20px;
            }
          }
        }
      }
    }
  }
}
