@import "../../../../global/homepage/scss/colors.scss";

.dealer-card-big-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    box-shadow: 0px 0px 16px $shadowColor;
    width: 270px;
    min-width: 270px;
    .dealer-card-big-header {
        display: flex;
        background-color: $brandOrange;
        max-height: 77px;
        .header-certificate {
            height: 100%;
            font-size: 12px;
            width: fit-content;
            .icon-certificate {
                margin-top: 15px;
                margin-left: 10px;
                height: 35px;
            }
        }
        .header-content {
            margin-left: 15px;
            .header-content-certificate {
                text-transform: uppercase;
                font-size: 12px;
                margin-top: 15px;
                color: $textLight;
            }
            .star-generate-wrapper {
                margin: 10px 0;
                font-weight: normal;
                .star-generate {
                    color: $textLight;
                }
                span {
                    color: $textLight;
                }
            }
        }
    }
    .dealer-card-big-photo {
        flex: 1 0 auto;
        width: 100%;
        height: 150px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dealer-card-big-footer {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: white;
        .dealer-name {
            font-size: 20px;
            color: $mainDark;
            text-decoration: none;
        }
        .dealer-data {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: uppercase;
            font-size: 12px;
        }
        .dealer-link {
            margin-top: 10px;
            text-decoration: none !important;
            .arrow-blue {
                padding: 4px;
            }
            span {
                margin-right: 15px;
                color: $brandBlue;
                font-size: 15px;
            }
            a {
                text-decoration: none !important;
            }
        }
    }
}


@media only screen and (min-width: 1200px) {
    .dealer-card-big-wrapper {
        width: 320px;
        min-width: 320px;
        .dealer-card-big-header {
            .header-content {
                .header-content-certificate {
                    font-size: 14px;
                }
            }
        }
        .dealer-card-big-photo {
            flex: 1 0 auto;
            width: 100%;
            height: 200px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .dealer-card-big-footer {
            .dealer-name {
                font-size: 24px;
                color: $mainDark;
                text-decoration: none;
            }
            .dealer-data {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: lighter;
            }
        }
    }
}
