.form-error {
  color: $alertError;
}

.errorlist {
  li {
    color: $alertError;
  }
}

.select2-container {
    min-width: 140px;
    .select2-selection {
        margin: 0 !important;
        padding: 0 10px;
        height: 35px;
        border-radius: 10px;
        border-color: $mainDark;
        border: 1px solid;
        font-size: 14px;
        color: $mainDark;
        outline: none;
        transition: none;
        .select2-selection__rendered {
            margin: 0;
            padding: 3px 0;
        }
        .select2-selection__arrow {
            margin: 2px 4px;
            b {
                border: solid $mainDark;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-135deg);
                transition: transform .5s;
            }
        }
    }
    &.select2-container--open {
        .select2-selection {
            .select2-selection__arrow {
                b {
                    border: solid $mainDark;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
.select2-dropdown {
    font-size: 14px;
    border-color: $mainDark;
    border-radius: 0;
    .select2-results__options {
        padding: 10px 0;
        .select2-results__option {
            padding: 5px 10px;
            font-size: 14px;
            &.select2-results__option--selected {
                background: $mainLight;
            }
            &.select2-results__option--highlighted {
                background: $brandOrange;
                color: white;
            }
        }
    }
}


.form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  .login-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .input-main {
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid $mainDark;
    padding: 10px 20px;
    margin: 0 0 15px 0;
    &:focus {
      outline: none;
      border-color: $brandOrange;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .range-time-picker {
    .label {
      margin-left: 5px;
      font-size: 12px;
    }
    .time-pickers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 15px;
      input {
        width: 45%;
        font-size: 12px;
        border-radius: 10px;
        border: 1px solid $mainDark;
        padding: 10px 20px;
        &:focus {
          outline: none;
          border-color: $brandOrange;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  .textarea-main {
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid $mainDark;
    padding: 10px 20px;
    margin-bottom: 15px;
    &:focus {
      outline: none;
      border-color: $brandOrange;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input-main[type="number"] {
    -moz-appearance: textfield;
  }
  .double-input-row {
    display: flex;
    justify-content: space-between;
  }
  .input-width-60 {
    width: 60%;
  }
  .select-default {
    height: 40px;
    margin-bottom: 15px;
    font-size: 12px;
  }
  .input-width-35 {
    width: 35%;
  }

  .submit-btn {
    width: 100%;
    margin: 30px auto;
  }

  .custom-control-label {
    padding: 5px;
    font-size: 12px;
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    .custom-control-label {
      position: relative;
      span {
        position: absolute;
        top: 2px;
        left: 10px;
        white-space: nowrap;
        font-size: 12px;
      }
      p {
        font-size: 12px;
        margin-left: 10px;
      }
    }
    margin-bottom: 20px;
    a {
      text-decoration: none;
      color: $mainDark;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 767px) {
    .select2-container {
        .select2-selection {
            font-size: 12px;
        }
    }
    .select2-dropdown {
        .select2-results__options {
            .select2-results__option {
                font-size: 12px;
            }
        }
    }
}


@media only screen and (min-width: 768px) {
  .form {
    padding: 30px;
    .login-title {
      font-size: 24px;
      margin-bottom: 30px;
      margin-left: 10px;
    }
    .input-main {
      font-size: 16px;
      padding: 15px 25px;
      max-height: 56px;
    }
    .textarea-main {
      font-size: 16px;
      padding: 15px 25px;
    }
    .select-default {
      height: 56px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .range-time-picker {
      .label {
        font-size: 16px;
      }
      .time-pickers {
        input {
          font-size: 16px;
        }
      }
    }
    .form-footer {
      .custom-control-label {
        span {
          font-size: 16px;
        }
      }
      a {
        font-size: 16px;
      }
    }
  }
}

.daterangepicker {
    .drp-buttons {
        .btn {
            line-height: 1;
            height: 40px;
            padding: 4px 16px !important;
            border-radius: 10px;
        }
    }
}


.crispy-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  .login-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .control-group {
    label {
      display: none;
    }
    .controls {

      input {
        font-size: 12px;
        border-radius: 10px;
        border: 1px solid $mainDark;
        padding: 10px 20px;
        width: 100%;
        margin-bottom: 15px;
        &:focus {
          outline: none;
          border-color: $brandOrange;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
  .custom-checkbox {
    label {
      display: block !important;
    }
  }

  .range-time-picker {
    .label {
      margin-left: 5px;
      font-size: 12px;
    }
    .time-pickers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 15px;
      input {
        width: 45%;
        font-size: 12px;
        border-radius: 10px;
        border: 1px solid $mainDark;
        padding: 10px 20px;
        &:focus {
          outline: none;
          border-color: $brandOrange;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  .textarea-main {
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid $mainDark;
    padding: 10px 20px;
    margin-bottom: 15px;
    &:focus {
      outline: none;
      border-color: $brandOrange;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input-main[type="number"] {
    -moz-appearance: textfield;
  }
  .double-input-row {
    display: flex;
    justify-content: space-between;
  }
  .input-width-60 {
    width: 60%;
  }
  .select-default {
    height: 40px;
    margin-bottom: 15px;
    font-size: 12px;
  }
  .input-width-35 {
    width: 35%;
  }

  .submit-btn {
    width: 100%;
    margin: 30px auto;
  }

  .custom-control-label {
    padding: 5px;
    font-size: 12px;
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    .custom-control-label {
      position: relative;
      span {
        position: absolute;
        top: 2px;
        left: 10px;
        white-space: nowrap;
        font-size: 12px;
      }
      p {
        font-size: 12px;
        margin-left: 10px;
      }
    }
    margin-bottom: 20px;
    a {
      text-decoration: none;
      color: $mainDark;
      font-size: 12px;
    }
  }
  &.reset-form-wrapper {
      p {
          margin-left: 10px;
      }
      label {
          display: none;
      }
      input:not([type=submit]) {
          font-size: 12px;
          border-radius: 10px;
          border: 1px solid $mainDark;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 15px;
          &:focus {
              outline: none;
              border-color: $brandOrange;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
      }
      .text-help {
          margin-top: 20px;
          font-size: 14px;
      }
  }
}

@media only screen and (min-width: 768px) {
  .crispy-form {
    padding: 30px;
    .login-title {
      font-size: 24px;
      margin-bottom: 30px;
      margin-left: 10px;
    }
    .control-group {
      label {
        display: none;
      }
      .controls {
        input {
          font-size: 16px;
          padding: 15px 25px;
          max-height: 56px
        }
      }
    }
    .textarea-main {
      font-size: 16px;
      padding: 15px 25px;
    }
    .select-default {
      height: 56px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .range-time-picker {
      .label {
        font-size: 16px;
      }
      .time-pickers {
        input {
          font-size: 16px;
        }
      }
    }
    .form-footer {
      .custom-control-label {
        span {
          font-size: 16px;
        }
      }
      a {
        font-size: 16px;
      }
    }
    &.reset-form-wrapper {
        input:not([type=submit]) {
            font-size: 16px;
            padding: 15px 25px;
            max-height: 56px;
        }
    }
  }
}

.all-auth-form {
  input {
      font-size: 12px;
      border-radius: 10px;
      border: 1px solid $mainDark;
      padding: 10px 20px;
      margin-bottom: 15px;
      width: 100%;
      &:focus {
        outline: none;
        border-color: $brandOrange;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  }
  .alert {
    font-size: 12px;
  }
  label {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    .alert {
      font-size: 14px;
    }
    input {
      font-size: 16px;
      padding: 15px 25px;
      max-height: 56px;
    }

  }

}
