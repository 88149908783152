.dealer-account {
.icon-wrapper {
  display: flex;
  flex-wrap: wrap;

  .icon {
    width: calc(20% - 15px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 10px;
    box-shadow: 0px 0px 16px rgba($fullBlack, 0.05);
    margin-bottom: 15px;
    margin-right: 15px;
    justify-content: flex-start;

    img {
      height: 47px;
      width: auto;
      margin-bottom: 23px;
    }

    p {
      font-size: 12px;
      text-align: center;
    }
  }
}

.img-added {
  height: 362px;
  width: 30%;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba($fullBlack, 0.05);

  @media only screen and (max-width: 1200px) {
    width: 346px;
    margin-right: 30px;
  }

  @media only screen and (max-width: 991px) {
    width: calc(50% - 15px);
    margin-right: 0;

    &:nth-of-type(odd) {
      margin-right: 30px;
    }
  }

  @media only screen and (max-width: 670px) {
    width: 100%;
    margin-right: 0 !important;
  }

  .box-top {
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    .button-delate {
      &:focus {
        outline: 0;
      }
    }
  }

  .content {
    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-title {
    .form-group-input-wrapper {
      .form-group {
        .form-control {
          border-radius: 0px 0px 15px 15px;
          height: 55px;
        }
      }
    }
  }

  &.logo {
    .content {
      img {
        height: 312px;
      }
    }
  }
}

.box-tooltip {
  padding: 21px 17px;
  transition: all 0.3s;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 570px) {
    padding: 10px 14px;
    display: block;
  }

  .icon {
    img {
      transition: all 0.3s;

      &.hover {
        display: none;
      }
    }
  }

  .tooltip-info {
    visibility: hidden;
    width: 390px;
    transition: all 0.3s;
    margin-left: 20px;

    @media only screen and (max-width: 670px) {
      width: 300px;
    }

    @media only screen and (max-width: 570px) {
      width: 250px;
      margin-left: 0;
      margin-top: 18px;
    }

    @media only screen and (max-width: 430px) {
      width: 210px;
    }

    @media only screen and (max-width: 390px) {
      width: 170px;
    }

    p {
      font-size: 14px;
      color: $buttonTextColor;
      margin-bottom: 0;
      line-height: 18px;

      @media only screen and (max-width: 670px) {
        font-size: 12px !important;
      }
    }
  }

  &:hover {
    background-color: $brandOrange;

    .icon {
      img {
        &:not(.hover) {
          display: none;
        }

        &.hover {
          display: block;
        }
      }
    }

    .tooltip-info {
      visibility: visible;
    }
  }
}

.box-review {
  box-shadow: 0px 0px 16px rgba($fullBlack, 0.05);
  padding: 30px;
  border-radius: 15px;

  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .name {
      font-size: 26px;
      margin-bottom: 0;
      margin-right: 20px;
      font-weight: 500;

      @media only screen and (max-width: 700px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }

  .review-answer {
    .box-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .name {
        margin-right: 10px;
      }

      img {
        height: 28px;
      }
    }
  }

  .stars-wrapper {
    width: max-content;
    display: flex;
    align-items: center;
    justify-self: start;

    @media only screen and (max-width: 500px) {
      flex-direction: column-reverse;
    }

    .rate {
      color: $brandOrange;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      margin-right: 20px;

      @media only screen and (max-width: 500px) {
        margin-right: 0;
        font-size: 12px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .stars {
      line-height: 16px;
      img {
        height: 16px;
        width: auto;
        margin-right: 1px;
        vertical-align: baseline;
        @media only screen and (max-width: 500px) {
          height: 12px;
        }
      }
    }
  }

  .review-info {
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 500px) {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      padding-right: 30px;
      padding-left: 30px;
      font-weight: lighter;
      border-right: 1px solid rgba($fullBlack, 0.1);

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border: none;
      }

      @media only screen and (max-width: 991px) {
        padding-right: 10px;
        padding-left: 10px;

        &:first-of-type {
          padding-left: 0;
        }
      }

      @media only screen and (max-width: 700px) {
        padding-left: 0;
        border: none;
        font-size: 14px;
        margin-bottom: 5px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 12px;
      }
    }

    .worker {
      a {
        color: $brandBlue;
        font-weight: 500;
      }

      img {
        margin-left: 10px;
        height: 28px;
      }
    }
  }

  .review-content-wrapper {
    display: flex;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }

    .review-img {
      margin-right: 30px;

      @media only screen and (max-width: 700px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        height: 190px;
        width: 180px;
        border-radius: 15px;
        object-fit: cover;

        @media only screen and (max-width: 700px) {
          width: 100%;
          height: 200px;
        }
      }
    }

    .review-content {
      width: calc(100% - 210px);

      @media only screen and (max-width: 700px) {
        width: 100%;
        margin-top: 15px;
      }

      .review-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;

        @media only screen and (max-width: 500px) {
          font-size: 14px;
        }
      }

      .review-text {
        font-size: 14px;
        margin-bottom: 30px;

        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      .content-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
    }

    &.no-img {
      .review-content {
        width: 100%;
      }
    }
  }

  .review-bottom-buttons {
    padding-top: 70px;
    text-align: center;

    @media only screen and (max-width: 991px) {
      padding-top: 30px;
    }
    @media only screen and (max-width: 650px) {
      padding-top: 10px;
    }

    .button-primary {
      font-size: 14px;
      width: 630px;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }

    .button-secondary {
      font-size: 14px;
      width: 630px;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }

    .divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba($mainDark, 0.1);
      margin-top: 40px;
      margin-bottom: 40px;
      @media only screen and (max-width: 650px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.review-box {
  width: 30%;
  box-shadow: 0px 0px 16px rgba($fullBlack, 0.1);
  border-radius: 15px;
  padding: 20px 30px 25px 30px;

  .box-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $mainLight;
    margin-bottom: 30px;
    display: flex;
    .icon-arrow {
      margin-left: auto;
    }
    p {
      margin-bottom: 0;
      margin-left: 20px;
      font-size: 20px;
      font-weight: 500;
    }
  }

  label {
    p,
    span {
      font-size: 18px !important;
    }
  }

  &.recommendation {
    .card-wrapper-radio {
      &:first-of-type {
        margin-bottom: 30px;
      }
    }
  }

  &.rate {
    color: $textLight;
    .stars {
      img {
        height: 40px;
      }
    }
  }

  &.type {
    .custom-radio {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    label {
      span {
        padding-left: 10px;
      }
    }
  }
}

.review-box-form {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 16px $shadowColor;
  width: 48%;

  .box-header {
    p {
      font-size: 26px;
      font-weight: 500;
    }

    padding-bottom: 20px;
  }

  .box-content {
    .stars {
      img {
        height: 38px;
      }

      padding-bottom: 30px;
      border-bottom: 1px solid $mainLight;
    }
  }

  .box-recommendation {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
  }
}

.box-info {
  padding: 30px;
  border-radius: 15px;
  background-color: $mainLight;

  .box-header {
    .icon-arrow {
      margin-left: auto;
    }
    display: flex;
    p {
      margin-left: 20px;
      margin-bottom: 0;
      font-size: 20px;
    }

    margin-bottom: 40px;
  }

  .box-content {
    overflow: hidden;
    .box-list {
      list-style: none;
      margin-left: 0;
      padding-left: 14px;

      li {
        padding-left: 19px;
        position: relative;
        font-size: 12px;

        &::before {
          position: absolute;
          left: 0;
          top: 5px;
          height: 9px;
          width: 9px;
          content: "";
          background-color: $brandOrange;
          border-radius: 50%;
        }

        margin-bottom: 24px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.box-conversation-review,
.box-conversation-review-answer {
  background-color: $brandOrange;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
  padding: 40px;
  color: $textLight !important;
  @media only screen and (max-width: 769px) {
    padding: 15px;
    margin-top: 0;
  }

  .box-conversation-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $backgroundWhite;

    p {
      font-size: 24px;
      font-weight: 700;
      color: $textLight;
    }
  }

  .box-content {
    margin-top: 30px;

    .box-review {
      box-shadow: none;
      padding: 0;

      .stars-wrapper {
        margin-left: 30px;
        align-items: baseline;
        .rate {
          color: $textLight;
          p {
            white-space: nowrap;
          }
        }
        @media only screen and (max-width: 500px) {
          margin-left: 15px;
          margin-right: 0;
          font-size: 12px;
        }

        .stars {
          img {
            @media only screen and (max-width: 500px) {
              height: 12px;
            }
          }
        }
      }

      .review-info {
        @media only screen and (max-width: 700px) {
          flex-direction: column;
        }

        @media only screen and (max-width: 500px) {
          margin-bottom: 15px;
        }

        p {
          border-color: $textLight;
          color: $textLight;
          @media only screen and (max-width: 991px) {
            padding-right: 10px;
            padding-left: 10px;

            &:first-of-type {
              padding-left: 0;
            }
          }

          @media only screen and (max-width: 700px) {
            padding-left: 0;
            border: none;
            font-size: 14px;
            margin-bottom: 5px;
          }

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }

      .review-content-wrapper {
        @media only screen and (max-width: 700px) {
          flex-direction: column;
        }

        .review-img {
          @media only screen and (max-width: 700px) {
            width: 100%;
            margin-right: 0;
          }

          img {
            @media only screen and (max-width: 700px) {
              width: 100%;
              height: 200px;
            }
          }
        }

        .review-content {
          @media only screen and (max-width: 700px) {
            width: 100%;
            margin-top: 15px;
            color: $textLight;
          }

          .review-title {
            color: $textLight;
            @media only screen and (max-width: 500px) {
              font-size: 14px;
            }
          }

          .review-text {
            color: $textLight;
            @media only screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
        }
      }

      .worker {
        a {
          color: $textLight;
        }
      }

      .box-header {
        .icon-arrow {
          margin-left: auto;
        }
        .name {
          color: $textLight;
          line-height: 1.2;
          word-break: break-word;
          @media only screen and (max-width: 700px) {
            font-size: 16px;
          }

          @media only screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }

    .rate {
      color: $textLight;
    }
  }
}

.box-conversation-review-answer {
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;

  .box-header {
    .icon-arrow {
      margin-left: auto;
    }
    justify-content: flex-start;

    img {
      margin-left: 15px;
    }
  }
}

.box-conversation-title {
  background-color: $brandOrange;
  border-radius: 5px;
  padding: 30px;
  color: $textLight;

  @include media(991px) {
    padding: 15px;
  }

  .box-header {
    display: flex;
    justify-content: space-between;

    @include media(600px) {
      flex-direction: column-reverse;
    }

    .title {
      font-size: 24px;
      font-weight: 700;

      @include media(991px) {
        font-size: 20px;
      }

      @include media(600px) {
        font-size: 16px;
      }
    }

    .date {
      font-size: 14px;
      font-weight: 500;

      @include media(600px) {
        font-size: 12px;
        margin-left: auto;
        margin-bottom: 0;
      }
    }
  }

  .box-content {
    font-size: 18px;
    font-weight: 500;
    display: flex;

    @include media(991px) {
      font-size: 16px;
    }

    @include media(600px) {
      flex-direction: column;
      font-size: 14px;
    }

    p {
      margin-bottom: 0;
    }

    .title {
      text-transform: uppercase;
      margin-right: 20px;
      display: inline-block;

      @include media(991px) {
        margin-right: 5px;
      }

      @include media(600px) {
        margin-bottom: 5px;
      }
    }

    .text {
      display: inline-block;
    }

    .receiver {
      margin-right: 50px;

      @include media(991px) {
        margin-right: 30px;
      }

      @include media(600px) {
        margin-right: 0;
      }
    }
  }
}

.box-conversation-message {
  .box-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 14px;
      margin-bottom: 0;

      @include media(600px) {
        font-size: 10px;
      }
    }

    .name {
    }

    .date {
    }
  }

  .box-content {
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 16px;

    @include media(600px) {
      font-size: 12px;
      padding: 15px;
    }

    img {
      width: 192px;
      height: 179px;
      border-radius: 15px;
      cursor: pointer;
    }
  }

  &.box-conversation-sent {
    .box-content {
      background-color: $mainLight;
    }
  }

  &.box-conversation-received {
    .box-content {
      background-color: $backgroundWhite;
    }
  }
}

.box-message-input {
  display: flex;
  border: 1px solid $mainLight;
  align-items: center;

  .box-button-img {
    width: 70px;
    position: relative;

    input {
      display: none;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus {
        outline: 0;
      }
    }

    &::before {
      content: "";
      width: 1px;
      height: 80%;
      top: 10%;
      right: 0;
      position: absolute;
      background-color: $mainLight;
    }
  }

  .input {
    width: calc(100% - 140px);
    @include media(600px) {
      width: calc(100% - 110px);
    }

    input {
      border: none;

      @include media(600px) {
        font-size: 12px;
      }

      &::placeholder {
        font-size: 16px !important;

        @include media(600px) {
          font-size: 12px !important;
        }
      }
    }
  }

  .button-send {
    width: 70px;
    @include media(600px) {
      width: 40px;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        @include media(600px) {
          height: 18px;
        }
      }
      &:focus {
        outline: 0;
      }
    }
  }
}
}
