.user-account {
.modal-dialog-messages {
    .modal-dialog {
        max-width: 650px;
        .modal-content {
            padding: 20px;
            .modal-title {
                font-size: 16px;
                text-align: center;
            }
            .modal-button  {
                width: 100%;
                font-size: 14px;
                padding: 10px;
                color: $buttonTextColor;
                margin: 8px 0;
                border-radius: 15px;
            }
            .quit-btn {
                background-color: $brandBlue;
            }
            .remove-button{
               background-color: $alertError;
             }
        }  
    }
}
}