.user-account {
.user-massage-table {
  .row {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;

    @media only screen and (max-width: 640px) {
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 10px;
      &.delete {
        transform: translateX(50px);
        .trash {
          transition-delay: 0.2s;
          opacity: 1;
        }
      }
    }

    p {
      margin-bottom: 0;

      @media only screen and (max-width: 640px) {
        font-size: 12px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .checkbox {
      padding-left: 20px;
      padding-right: 15px;
      width: 55px;

      @media only screen and (max-width: 640px) {
        display: none;
      }

      // .custom-checkbox {
      //     .custom-control-label {
      //         &:after {
      //             left: -30px;
      //         }
      //     }
      // }
    }

    .trash {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      transition: opacity 0.3s;

      @media only screen and (max-width: 640px) {
        position: absolute;
        left: -60px;
        opacity: 0;
      }

      img {
      }
    }

    .name {
      margin-left: 40px;
      margin-right: 30px;
      max-width: 20%;
      white-space: nowrap;
      overflow: hidden;

      p {
        &:first-of-type {
          @media only screen and (max-width: 640px) {
            font-weight: 700;
          }
        }
      }

      @media only screen and (max-width: 991px) {
        max-width: unset;
        width: 47%;
      }

      @media only screen and (max-width: 769px) {
        width: 40%;
      }

      @media only screen and (max-width: 640px) {
        margin-left: 23px;
        width: calc(100% - 180px);
        margin-right: 15px;
      }

      .title-mobile {
        display: none;

        @media only screen and (max-width: 991px) {
          display: block;
        }

        @media only screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    .date {
      margin-right: 30px;
    }

    .message {
      display: flex;
      margin-right: 30px;
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (max-width: 991px) {
        display: none;
      }

      @media only screen and (max-width: 640px) {
        display: block;
        margin-left: 23px;
      }

      .title {
      }

      .masaage-excerpt {
        opacity: 0.7;
        margin-left: 5px;
        @media only screen and (max-width: 1200px) {
          display: none;
        }
      }
    }

    .button-open {
      width: 40px;
      margin-left: auto;

      @media only screen and (max-width: 640px) {
        position: absolute;
        right: 0;
        text-align: center;
      }

      .hover {
        display: none;
      }
    }
    // AS9864
    //     &.new-message {
    //       font-weight: 700;
    //     }
    @media only screen and (min-width: 641px) {
      &:hover {
        background-color: $brandOrange;
        color: $textLight;

        .checkbox {
          .custom-checkbox {
            .custom-control-label {
              &::before {
                background-color: $brandOrange;
                border-color: $textLight;
              }
            }
          }
        }

        .button-open {
          .hover {
            display: block;
          }

          img:not(.hover) {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 640px) {
      &.select {
        background-color: $brandOrange;
        color: $textLight;

        .checkbox {
          .custom-checkbox {
            .custom-control-label {
              &::before {
                background-color: $brandOrange;
                border-color: $textLight;
              }
            }
          }
        }

        .button-open {
          .hover {
            display: inline-block;
          }

          img:not(.hover) {
            display: none;
          }
        }
      }
    }
  }
}

.user-opinion-table {
  .row {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 720px) {
      flex-direction: column;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    p {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .btn-tooltip {
      &.mobile {
        display: none;

        @media only screen and (max-width: 720px) {
          display: block;
        }
      }
    }

    .status {
      display: flex;
      padding-left: 25px;
      padding-right: 25px;

      @media only screen and (max-width: 720px) {
        order: 2;
        width: 100%;
        padding-left: 0;
        padding-right: 40px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .btn-tooltip {
        @media only screen and (max-width: 720px) {
          display: none;
        }

        img {
        }
      }

      .name {
        padding-left: 23px;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media only screen and (max-width: 720px) {
          padding-left: 0;
          width: 100%;
        }
      }
    }

    .rate {
      display: flex;
      align-items: center;
      width: 160px;
      margin-right: 30px;

      @media only screen and (max-width: 720px) {
        order: 3;
        width: calc(100% - 40px);
      }

      .indicatior {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 12px;

        &.positive {
          background-color: $alertCorrect;
        }

        &.negative {
          background-color: $alertError;
        }
      }
      p {
        white-space: nowrap;
      }
    }

    .date {
      margin-right: 30px;

      @media only screen and (max-width: 720px) {
        order: 1;
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
      }
      .btn-tooltip {
        @media only screen and (max-width: 720px) {
          margin-right: 20px;
        }
      }
    }

    .button-open {
      width: 40px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media only screen and (max-width: 720px) {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
}