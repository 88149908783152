.worker-account {
.table.dataTable {
  border: none;
  border-spacing: 0 15px;
  &.no-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  thead {
    tr {
      th {
        padding: 0 10px;
        border: none;
        font-size: 12px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        white-space: nowrap;
        &.sorting {
          &:after {
            content: url(@/icons/Icon-sort-up-down.svg);
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.sorting_desc {
          color: $brandOrange;
          &:after {
            content: url(@/icons/Icon-sort-up-down-active.svg);
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.sorting_asc {
          color: $brandOrange;
          &:after {
            content: url(@/icons/Icon-sort-down-up-active.svg);
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  tbody {
    tr {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      td {
        button:active {
          outline: none;
          border: none;
        }
        padding: 20px 10px;
        border: none;
        font-size: 12px;
        vertical-align: middle;
        &:first-of-type {
          border-radius: 5px 0 0 5px;
        }
        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }
        &.dataTables_empty {
          border-radius: 5px;
        }
        i {
          &:after {
            content: "";
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            display: inline-block;
            width: 30px;
            height: 20px;
          }
          &.icon-preview {
            &:after {
              background-image: url("@/icons/Icon-preview.svg");
            }
          }
          &.icon-mail-table {
            &:after {
              background-image: url("@/icons/Icon-mail-table.svg");
            }
          }
          &.icon-worker-start {
            &:after {
              background-image: url("@/icons/Icon-worker-star.svg");
            }
          }
          &.icon-worker-mail {
            &:after {
              background-image: url("@/icons/Icon-worker-mail.svg");
            }
          }
          &.i-disabled {
            opacity: 0.33;
          }
        }
        .row-status {
          display: inline-block;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          &[data-status="accepted"] {
            background: $alertCorrect;
          }
          &[data-status="rejected"] {
            background: $alertError;
          }
          &[data-status="pending"] {
            background: $mainDark;
          }
        }
        .row-is-positive {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-size: 100%;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;

          &[data-status="negative"] {
            background-color: $alertError;
            background-image: url("@/icons/icon-emoji-sad.svg");
          }
          &[data-status="positive"] {
            background-color: $alertCorrect;
            background-image: url("@/icons/icon-emoji-happy.svg");
          }
        }
        &.row-actions {
          padding: 0;
          .dropdown {
            .dropdown-toggle {
              position: relative;
              padding: 5px 10px;
              width: 26px;
              box-shadow: none;
              border: none;
              &:after {
                content: "";
                background-image: url("@/icons/icon-options.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -13px;
                width: 26px;
                height: 26px;
                border: none;
              }
            }
            .dropdown-menu {
              margin: 0;
              top: -22px;
              right: 0;
              left: auto;
              border: none;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
              @media only screen and (max-width: 991px) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                justify-content: center;
                align-items: center;
                background-color: rgba(255, 255, 255, 0.8);
                form {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                }
                .options-box {
                  position: relative;
                }
              }
            }
            &.open {
              .dropdown-menu {
                display: flex im !important;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    thead {
      tr {
        th {
          font-size: 11px;
          &.sorthing:after {
            margin-left: 5px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    display: block;
    margin: 0 -15px;
    padding: 0 15px 15px;
    width: 100%;
    overflow: auto;
    border-spacing: 0 10px;
    &:after {
      content: "";
      width: 15px;
      height: 0;
    }
    tbody {
      tr {
        td {
          padding: 10px;
        }
      }
    }
  }
}
.table-sorting {
  display: flex;
  margin-bottom: 20px;
  &.border-top {
    border-top: 1px solid rgba($mainDark, 0.1) !important;
  }
  .sorting-main {
    flex: 1 1 auto;
    display: flex;
    label {
      margin: 0 20px 0 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 50px;
      white-space: nowrap;
    }
    .sorting-dropdown {
      option {
        font-weight: 500;
      }
      .option-worker {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .sorting-tools {
    button {
      padding: 15px 0;
      color: $brandBlue;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      outline: none;
      img {
        margin-left: 20px;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
    .sorting-tools {
      order: 1;
      width: 100%;
      text-align: right;
    }
    .sorting-main {
      order: 2;
      justify-content: space-between;
    }
    &.workers {
      flex-wrap: nowrap;
      margin-bottom: 10px;
      .sorting-main {
        order: 1;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .sorting-main {
      flex-wrap: wrap;
      label {
        margin: 0;
        font-size: 14px;
      }
      .sorting-dropdown {
        margin: 0 0 10px !important;
        width: 100%;
      }
      .input-custom-date {
        width: 100%;
      }
    }
    .sorting-tools {
      button {
        font-size: 14px;
      }
    }
  }
}
.dataTables_wrapper {
  .dataTables_processing {
    font-size: 12px;
    padding: 12px;
  }
  .dataTables_info {
    padding: 10px 0;
    font-size: 12px;
    line-height: 30px;
    opacity: 0.6;
  }
  .dataTables_length {
    font-size: 12px;
    margin: 0 15px;
    padding: 10px 0;
    select {
      padding: 5px;
      line-height: 20px;
      height: 30px;
    }
    label {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      margin: 10px 0 0;
      width: 100%;
      text-align: center;
    }
  }
  .dataTables_paginate {
    padding-top: 10px;
    font-size: 12px;
    a.paginate_button {
      height: 30px;
      line-height: 1.4;
      margin: 0 3px !important;
      color: $mainDark;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
      border-radius: 3px !important;
      background: none;
      &.current {
        font-weight: bold;
        background: $brandBlue;
        color: white !important;
        border: 1px solid transparent !important;
        &:hover {
          background: $brandBlue;
          color: white !important;
          border: 1px solid transparent !important;
        }
      }
      &.disabled {
        opacity: 0.5;
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.2) !important;
          border-radius: 3px !important;
          background: none;
        }
      }
      &:hover {
        background: $brandBlue;
        color: white !important;
        border: 1px solid transparent !important;
      }
    }
  }
}

.user-massage-table {
  .row {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    @media only screen and (max-width: 640px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    p {
      margin-bottom: 0;

      @media only screen and (max-width: 640px) {
        font-size: 12px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .checkbox {
      padding-left: 20px;
      padding-right: 15px;
      width: 55px;

      @media only screen and (max-width: 640px) {
        display: none;
      }
    }

    .trash {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;

      @media only screen and (max-width: 640px) {
        display: none;
      }
    }

    .name {
      margin-left: 40px;
      margin-right: 30px;
      max-width: 20%;
      white-space: nowrap;
      overflow: hidden;

      p {
        &:first-of-type {
          @media only screen and (max-width: 640px) {
            font-weight: 700;
          }
        }
      }

      @media only screen and (max-width: 991px) {
        max-width: unset;
        width: 47%;
      }

      @media only screen and (max-width: 769px) {
        width: 40%;
      }

      @media only screen and (max-width: 640px) {
        margin-left: 23px;
        width: calc(100% - 180px);
        margin-right: 15px;
      }

      .title-mobile {
        display: none;

        @media only screen and (max-width: 991px) {
          display: block;
        }

        @media only screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    .date {
      margin-right: 30px;
    }

    .message {
      display: flex;
      margin-right: 30px;
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (max-width: 991px) {
        display: none;
      }

      @media only screen and (max-width: 640px) {
        display: block;
        margin-left: 23px;
      }

      .masaage-excerpt {
        opacity: 0.7;
        margin-left: 5px;
        @media only screen and (max-width: 1200px) {
          display: none;
        }
      }
    }

    .button-open {
      width: 40px;
      margin-left: auto;

      @media only screen and (max-width: 640px) {
        position: absolute;
        right: 0;
      }

      .hover {
        display: none;
      }
    }
// AS9864
//     &.new-message {
//       font-weight: 700;
//     }

    &:hover {
      background-color: $brandOrange;
      color: $textLight;

      .checkbox {
        .custom-checkbox {
          .custom-control-label {
            &::before {
              background-color: $brandOrange;
              border-color: $textLight;
            }
          }
        }
      }

      .button-open {
        .hover {
          display: block;
        }

        img:not(.hover) {
          display: none;
        }
      }
    }
  }
}

.user-opinion-table {
  .row {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba($fullBlack, 0.1);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 720px) {
      flex-direction: column;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    p {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .btn-tooltip {
      &.mobile {
        display: none;

        @media only screen and (max-width: 720px) {
          display: block;
        }
      }
    }

    .status {
      display: flex;
      padding-left: 25px;
      padding-right: 25px;

      @media only screen and (max-width: 720px) {
        order: 2;
        width: 100%;
        padding-left: 0;
        padding-right: 40px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .btn-tooltip {
        @media only screen and (max-width: 720px) {
          display: none;
        }
      }

      .name {
        padding-left: 23px;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media only screen and (max-width: 720px) {
          padding-left: 0;
          width: 100%;
        }
      }
    }

    .rate {
      display: flex;
      align-items: center;
      width: 160px;
      margin-right: 30px;

      @media only screen and (max-width: 720px) {
        order: 3;
        width: calc(100% - 40px);
      }

      .indicatior {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 12px;

        &.positive {
          background-color: $alertCorrect;
        }

        &.negative {
          background-color: $alertError;
        }
      }
      p {
        white-space: nowrap;
      }
    }

    .date {
      margin-right: 30px;

      @media only screen and (max-width: 720px) {
        order: 1;
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
      }
      .btn-tooltip {
        @media only screen and (max-width: 720px) {
          margin-right: 20px;
        }
      }
    }

    .button-open {
      width: 40px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media only screen and (max-width: 720px) {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

.save-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: fit-content;
  background-color: $backgroundWhite;
  z-index: 100000;
  @media only screen and (max-width: 615px) {
    padding: 10px 15px;
    position: fixed;
    width: 100%;
    box-shadow: 0px 0px 16px rgba($fullBlack, 0.1);
  }
}
}