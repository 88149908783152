@import "../../global/homepage/scss/colors.scss";

.not-display {
  display: none;
}

.social_wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  .social_wrapper_title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .social_add {
    font-size: 16px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0;
  }

  .socialaccount_delete {
    margin-top: 10px;
    margin-bottom: 15px;
  }

}

@media only screen and (min-width: 768px) {
  .social_wrapper {
    padding: 30px;
    .social_wrapper_title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .social_add {
      font-size: 24px;
    }
  }
}

.login-wrapper {
  background-color: $mainLight;
  position: relative;
  overflow: hidden;
  .login-section {
    min-height: 700px;
    max-width: 100vw;
    background: url(@/homepage/BG-img.svg) no-repeat center top;
    background-size: cover;
    padding-top: 150px;
    .form-wrapper {
      width: 350px;
      margin: 0 auto 10px auto;
      background: $backgroundWhite;
      border-radius: 10px;
      overflow: hidden;
      .form-accept {
        margin: 20px 0 40px;
        .additional-info {
          p {
            padding: 5px 5px 20px;
            margin: 0;
            font-size: 12px;
            font-weight: 300;
          }
        }
        .collapse.in {
          display: block;
        }
      }
      .socialaccount_ballot {
        padding: 0 15px 0 15px;
        .socialaccount_providers {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin: 0 0 0 0;
          padding: 0 0 0 0;
          .socialaccount_provider {
            margin: 15px 5px;
          }
          .facebook {
            .btn {
                background: white;
                border-radius: 0px;
                border: 2px solid #3b5998;
                min-width: 140px;
                color: #354052;
            }
          }
          .google {
            .btn {
                background: white;
                border-radius: 0px;
                border-right: 2px solid #4285f4;
                border-bottom: 2px solid #34a853;
                border-left: 2px solid #fbbc04;
                border-top: 2px solid #ea4335;
                min-width: 140px;
                color: #354052;
            }
          }
        }
      }
      .control-group.error {
         display: block;
         .help-block {
             margin-top: -5px;
             font-size: 14px;
         }
         input {
             border-color: $alertError;
         }
      }
      .tabs-wrapper {
        width: 101%;
      }
      .login-form-footer-wrapper {
        padding: 0 15px 15px 15px;
        .login-form-footer {
          border-top: 1px solid $shadowColor;
          padding: 30px 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          p {
            margin: 0 0 20px 0;
            font-size: 12px;
          }
        }
      }
      .socialaccount_wrapper {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
  .login-car-img {
    position: absolute;
    height: 50px;
    left: -25px;
    bottom: 50px;
    display: none;
  }
  .login-person-img {
    position: absolute;
    right: 0;
    height: 150px;
    bottom: 20px;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .login-wrapper {
    .login-section {
      padding-top: 150px;
      min-height: 700px;
      margin-bottom: 150px;
      .form-wrapper {
        width: 700px;
        z-index: 1;
        position: relative;
        .login-form-footer-wrapper {
          padding: 0 30px 30px 30px;
          .login-form-footer  {
            flex-direction: row;
            p {
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
    .login-car-img {
      left: -100px;
      height: 200px;
      bottom: 50px;
      display: block;
    }
    .login-person-img {
      right: 0;
      height: 200px;
      bottom: 5vh;
      display: none;
    }
  }
}


@media only screen and (min-width: 1200px) {
  .login-wrapper {
    .login-car-img {
      left: -350px;
      height: 300px;
      bottom: 150px;
    }
    .login-person-img {
      right: -20px;
      height: 400px;
      bottom: 5vh;
      display: block;
    }
  }
}
