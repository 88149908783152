.not-display {
  display: none !important;
}
.modal .modal-dialog .modal-content {
  max-width: unset !important;
}
.modal-contact-form-data {
  padding-bottom: 50px;
  .close-btn {
    z-index: 100;
    top: 0;
    height: 50px;
    width: 50px;
    &::after {
      top: -20px;
      color: $mainDark !important;
    }
  }
  .modal-dialog {
    max-width: 100vw !important;
    width: 100%;
    margin-top: 0 !important;
    .modal-content {
      width: 100vw !important;
      padding-top: 50px;
      min-height: 100vh;
      max-width: unset !important;
      border-radius: 0;
      .form-contact {
        width: 100%;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
        .form-title {
          font-size: 16px;
          margin: 10px 15px;
          color: $brandBlue;
          font-weight: bold;
        }
        .form-sub-title {
          font-size: 14px;
          margin: 10px 15px;
          color: $brandBlue;
        }
        .button-submit-wrapper {
          position: fixed;
          bottom: 0;
          width: 100vw;
          // Show full-width button
          padding: 10px 15px;
          z-index: 10;
          background: $backgroundWhite;
          left: 0;
          button {
            margin: 0;
          }
        }
        .form {
          input,
          select,
          textarea {
            width: 100%;
          }
          .separator {
            margin: 0 10px;
          }
          .collapse.in {
            display: block;
          }
        }
      }
      .select-contact-item {
        padding: 0 15px 30px;
        width: 100%;
        .select-describe {
          font-size: 14px;
          font-weight: bold;
        }
        .select-contact-title {
          margin: 0;
          font-size: 18px;
          @media (max-width: 767px) {
            padding-top: 10px;
            margin: 0;
            font-size: 14px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
        .select-contact-option {
          .radio-buttons {
            margin: 25px 0;
            padding: 0;
          }
          .radio-container {
            margin-bottom: 17px;
            p {
              line-height: 1;
              @media (max-width: 767px) {
                font-size: 12px;
                line-height: 1.5;
              }
            }
          }
        }
        .select-contact-person-wrapper {
          margin-top: 25px;
          @media (max-width: 767px) {
            margin-left: -15px;
            margin-right: -15px;
            .tabs-wrapper {
              margin: 0 15px;
            }
          }
          .dealer-card-small {
            width: 100%;
            margin: 15px auto;
          }
          .tabs-wrapper {
            .tab {
              font-size: 16px;
              &.active-tab {
                background: $mainLight;
                font-weight: bold;
              }
            }
          }
          .tab-content {
            background: $mainLight;
            padding: 15px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    padding-bottom: 0;
    .modal-dialog {
      .modal-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;

        .form-contact {
          width: 100%;
          max-width: 700px;
          .form-title {
            font-size: 24px;
            margin: 15px 25px;
            color: $brandBlue;
            font-weight: bold;
          }
          .form-sub-title {
            font-size: 20px;
            margin: 15px 25px;
            color: $brandBlue;
          }
          .button-submit-wrapper {
            position: unset;
            padding: 10px 0;
            width: 100%;
            button {
              margin-top: 15px;
            }
          }
        }
        .select-contact-item {
          width: 100%;
          margin-bottom: 20px;
          max-width: 700px;
          padding: 0 30px;
          .tabs-wrapper {
            display: flex;
            justify-content: flex-end;
            .tab {
              font-size: 18px;
            }
            #dealer-tab {
              border-top-left-radius: 10px;
            }
          }
          .tab-content {
            padding: 15px 30px;
            .workers-available-title,
            .workers-rest-title {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .modal-dialog {
      .modal-content {
        .form-contact {
          width: 40%;
        }
        .select-contact-item {
          width: 60%;
          .select-contact-person-wrapper {
            margin-top: 80px;
          }
        }
      }
    }
  }
}
