.leasing {
  &--form {
    max-width: 800px;
  }

  &--search-car-img {
    left: -100px;
    height: 150px;
    position: absolute;
    bottom: 100px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &--title {
    text-align: left;
    font: normal normal bold 18px/34px Montserrat, serif;
    letter-spacing: 0;
    color: #404040;
    opacity: 1;
  }

  &--card-img-top {
    box-shadow: 0 3px 6px #00000029;
    border-radius: 12px;
    object-fit: cover;
    height: 150px;
    width: 100%;
  }

  &--card {
    &--wrapper {
      display: flex !important;
      justify-content: space-evenly !important;
    }

    @media screen and (min-width: 490px) {
      max-width: 190px;
    }

    @media screen and (min-width: 768px) {
      max-width: 230px;
    }

    @media screen and (min-width: 992px) {
      max-width: 200px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 240px;
    }

    min-width: 170px;
    border: none;

    &--title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
    }

    .card-body {
      padding: 5px;
    }
  }

  &--h2 {
    background-color: #404040;
    height: 54px;
    width: 100%;
    font: normal normal normal 18px/34px Montserrat, serif;
    letter-spacing: 0;
    opacity: 1;
    display: flex;
    color: #f3f3f3;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  &--container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0 10px 20px #0000000d;
    border-radius: 10px;
    opacity: 1;

    &--header {
      border-radius: 10px 10px 0 0;
      opacity: 1;
      margin-right: 0;
      margin-left: 0;
      overflow: hidden;
    }

    &--how {
      display: flex;
      height: 30px;

      a {
        text-align: left;
        text-decoration: underline;
        font: normal normal normal 10px/8px Montserrat, serif;
        letter-spacing: 0;
        color: black;
        opacity: 1;
        margin: auto;
        margin-right: 20px;
      }
    }
  }

  &--advanced-search {
    &--main-form {
      margin-top: 120px;
    }
  }

  &--card:last-of-type {
    display: none;

    @media screen and (min-width: 992px) {
      display: inline-block;
    }
  }

  &--card:nth-last-of-type(2) {
    display: none;

    @media screen and (min-width: 490px) {
      display: inline-block;
    }
  }
}
