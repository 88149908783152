.user-account {
.user-message-details {
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;

    .button-delate {
      img {
        margin-right: 5px;
      }
      &:focus {
        outline: 0;
      }
      @media only screen and (max-width: 769px) {
        font-size: 14px;
      }

      @media only screen and (max-width: 400px) {
        font-size: 12px;
      }
    }

    .button-arrow-back {
      &:focus {
        outline: 0;
      }
      display: flex;

      @media only screen and (max-width: 400px) {
        font-size: 12px;
      }
    }

    .additional {
      @media only screen and (max-width: 769px) {
        display: none;
      }
    }
  }

  .message-area {
    .box-conversation-review {
      margin-bottom: 30px;
      width: 100%;

      .box-content {
        margin-top: 0;
      }

      .review-info {
        @media screen and (max-width: 1200px) {
          flex-wrap: wrap;
        }

        .type {
          @media screen and (max-width: 1200px) {
            border: none;
          }
        }

        .worker {
          @media screen and (max-width: 1200px) {
            width: 100%;
            padding-left: 0;
          }
        }
      }

      &.answered {
        margin-bottom: 0;
        border-radius: 5px 5px 0px 0px;
        padding-bottom: 15px;

        .divider {
          text-align: center;
          border-top: 1px solid $backgroundWhite;
          padding-top: 15px;
          font-size: 14px;
          font-weight: 500;

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }

    .box-conversation-review-answer {
      padding-top: 15px;
      margin-bottom: 30px;

      .box-content {
        margin: 0;
      }
    }

    .box-conversation-title {
      margin-bottom: 30px;
      width: 100%;
    }

    .box-conversation-wrapper {
      display: flex;
      flex-direction: column;

      .box-conversation-received {
        width: 90%;
        margin-bottom: 30px;

        .contact-deatils {
          margin-top: 15px;

          p {
            margin-bottom: 0;
            font-weight: 700;
          }
        }
      }

      .box-conversation-sent {
        margin-bottom: 30px;
        width: 90%;
        margin-left: auto;
      }
    }
  }
}

#user-message-details {
  display: none;
}

.box-header {
  .stars-wrapper {
    .rate {
      p {
        color: $textLight;
      }
    }
  }
  .icon-arrow {
    transition: 0.2s transform;
    &.mobile{
      @media only screen and (min-width: 1200px) {
        display: none;
      }
    }
  }
  &:not(.collapsed) {
    .icon-arrow {
      transform: rotate(180deg);
    }
  }
}
}