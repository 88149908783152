@import "../../../global/homepage/scss/colors.scss";

.blog-container {
  padding: 90px 30px 0px 30px;
  transform: none;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  clear: both;
  box-sizing: inherit;
}

.blog-content {
  width: 65%;
  order: 2;
  box-sizing: inherit;
  h1 {
    font-size: 22px;
  }

  .post {
    .title_image {
        display: flex;
        @media only screen and (max-width: 1050px) {
            display: block;
        }
        .image_holder {
            width: 315px;
            @media only screen and (max-width: 512px) {
                width: -webkit-fill-available;
            }
        }
        img {
          object-fit: cover;
          width: -webkit-fill-available;
          margin-right: 10px;
          border-radius: 15px;
          @media only screen and (max-width: 1050px) {
            margin-bottom: 10px;
            margin-right: 0px;
          }
        }
    }
    .post-meta {
        margin-top: 10px;
    }
    .teaser {
      p {
        text-align: justify;
      }
    }
    .content {
      figure {
        figcaption {
          text-align: center;
        }
      }
      blockquote {
        p {
          text-align: center;
        }
      }
      p {
        text-align: justify;
      }
    }
  }
}

.sidebar {
  position: relative;
  overflow: visible;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-height: 1px;
  width: 30%;
  order: 2;
  margin-left: auto;
  .profile-picture {
    padding-bottom: 10px;
  }
  .feeds{
    padding-bottom: 10px;
  }
  .search{
    padding-bottom: 10px;
    .form-search{
      .search-input{
        input[type="search"] {
          width:  100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 934px) {
  .blog-content {
    width: 100%;
  }
  .sidebar {
    width: 100%;
  }

  h2 {
    font-size: 24px;
  }
}

.homepage-blog-container {
  padding: 20px 30px 0px 30px;
  transform: none;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  clear: both;
  box-sizing: inherit;

  .blog-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    order: 2;
    box-sizing: inherit;
    text-align: center;
    h1 {
      font-size: 22px;
    }

    .post {
      border: 1px solid;
      border-radius: 15px;
      border-color: $mainLight;
      padding: 5px;
      max-width: 50%;

      @media only screen and (max-width: 1050px) {
          width: 100%;
          max-width: 100%;
      }

      .post_date {
      }

      .title_image {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 1050px) {
              display: block;
          }
          .image_holder {
              width: 315px;
              @media only screen and (max-width: 512px) {
                  width: -webkit-fill-available;
              }
          }
          img {
            object-fit: cover;
            width: -webkit-fill-available;
            margin-right: 10px;
            border-radius: 15px;
            @media only screen and (max-width: 1050px) {
              margin-bottom: 10px;
              margin-right: 0px;
            }
          }
      }
      .post-meta {
          margin-top: 10px;
      }
      .teaser {
        p {
          text-align: justify;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 8; /* number of lines to show */
          line-clamp: 8;
          -webkit-box-orient: vertical;
        }
      }
      .content {
        figure {
          figcaption {
            text-align: center;
          }
        }
        blockquote {
          p {
            text-align: center;
          }
        }
        p {
          text-align: justify;
        }
      }
    }
  }
}
