@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Prometo';
  src: local('prometo'),
        url('@/fonts/prometo/Prometo.woff2') format("woff2"),
        url('@/fonts/prometo/Prometo.woff') format("woff"),
        url('@/fonts/prometo/Prometo.ttf') format("truetype");
        font-display: swap;
        font-style: normal;
        font-weight: 400;
}

body {
    font-family: 'Montserrat', sans-serif;
    color: $mainDark;
    footer {
        font-family: 'Montserrat', sans-serif;
        color: $mainDark;
    }
}
