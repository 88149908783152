@import "./colors.scss";
@import "./_emailSent.scss";
@import "./_fonts.scss";
@import "./_buttons.scss";
@import "./_inputs.scss";
@import "./_tabs.scss";
@import "./_forms.scss";
@import "./_overrideBootstrap.scss";
@import "./_elements.scss";
@import "./_mixins.scss";
@import "./_modal.scss";
@import "./_flatPages";


.button-wrapper-submit {
  background: $backgroundWhite;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  bottom: 0;
}
#correct-sent-opinion {
  display: none;
  border-top: 1px solid #000;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  position: relative;
  p {
    color: $mainDark;
  }
  button {
    width: 200px;
    background: $brandBlue;
    color: $buttonTextColor;
  }
  h4 {
    padding-top: 20px;
    a {
      color: #f6a335;
    }
  }
}