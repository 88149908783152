.dealer-account {
.dealeraccount–gallery {
.img-box-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .add-img {
        cursor: pointer;
        position: relative;
        margin-bottom: 40px;
        padding: 100px 24px 30px;
        height: 362px;
        width: 30%;
        border-radius: 15px;
        border: 2px dashed rgba($mainDark, 0.2);
        overflow: hidden;

        @media only screen and (max-width: 1200px) {
            width: 346px;
            margin-right: 30px;
        }

        @media only screen and (max-width: 991px) {
            width: calc(50% - 15px);
            margin-right: 0;

            &:nth-of-type(odd) {
                margin-right: 30px;
            }
        }

        @media only screen and (max-width: 670px) {
            width: 100%;
            margin-right: 0 !important;
        }

        .img-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
        }

        .icon {
            opacity: 0.5;
            height: 54px;
            margin-bottom: 22px;
        }

        .specification {
            flex: 1 0 auto;
            font-size: 12px;
            line-height: 15px;
        }

        .button-secondary-icon {
            width: 100%;
            text-align: center;
            padding: 14px;
            &:focus {
                outline: 0;
            }
        }

        .button-primary-icon {
            margin-top: auto;
            width: 100%;
            padding: 14px;
            &:focus {
                outline: 0;
            }
        }

        .form-group {
            margin: 0;
            font-size: 0;
            text-indent: 100%;
            * {
                display: none;
            }
            input[type="file"] {
                cursor: pointer;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                z-index: 2;
            }
        }

        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &[src=""] {
                    display: none;
                }
            }
            .button-primary {
                position: absolute;
                bottom: 30px;
                left: 24px;
                right: 24px;
                width: auto;
                text-align: center;
                opacity: 0;
                z-index: 3;
                transition: opacity .3s;
            }
        }

        .button-delete {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 40px;
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
            background: $mainLight;
            box-shadow: 0 5px 10px rgba(0,0,0,.25);
            outline: none;
            z-index: 3;
            transition: all .3s;
            &:hover {
                background: white;
                box-shadow: 0 0 0 rgba(0,0,0,0);
            }
            img {
                display: inline-block;
                margin-right: 10px;
                height: 16px;
                vertical-align: bottom;
            }
        }

        &.loaded {
            border: 2px solid rgba($mainDark, 0.2);
            .img-wrapper {
                opacity: 1;
            }
            .button-delete {
                display: block;
            }
        }

        &:hover {
            border: 2px solid rgba($mainDark, 0.2);
            .button-secondary-icon {
                box-shadow: 0px 10px 20px $shadowColor;
            }
            .img-wrapper {
                .button-primary {
                    opacity: 1;
                }
            }
        }
    }
}
}
}