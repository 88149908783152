.user-account {
.tabs-big-icon {
  .tabs {
    padding-left: 0;
    display: flex;
  }

  .tab {
    padding: 13px 35px;
    background-color: $mainLight;
    color: $mainDark;
    list-style: none;
    width: max-content;
    display: flex;
    margin-right: 2px;
    cursor: pointer;
    @media only screen and (max-width: 520px) {
      padding: 15px;
    }

    &.active {
      background-color: $mainDark;
      color: $buttonTextColor;
      p {
        font-weight: 700;
      }

      img.active {
        display: inline-block;
        @media only screen and (max-width: 680px) {
          display: none;
        }
      }

      img:not(.active) {
        display: none;
      }
    }

    &:first-of-type {
      border-radius: 10px 0px 0px 0px;
    }

    &:last-of-type {
      border-radius: 0px 10px 0px 0px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      @media only screen and (max-width: 680px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 520px) {
        font-size: 12px;
      }
    }

    img {
      height: 22px;
      width: auto;
      margin-right: 17px;
      @media only screen and (max-width: 680px) {
        display: none;
      }
    }

    img.active {
      display: none;
    }
  }

  &.user-tabs {
    .tabs {
      margin-bottom: 0;
    }
    .tab {
      background-color: $mainDark;
      p {
        color: $backgroundWhite;
      }

      &.active {
        background-color: $backgroundWhite;

        p {
          color: $mainDark;
        }
      }
    }
  }
}
.tabs-typo {
  .tabs {
    padding-left: 0;
    display: flex;
    list-style: none;
    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    .tab {
      padding: 14px 20px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        padding: 14px 18px;
      }
      @media only screen and (max-width: 500px) {
        padding: 0;
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 0;
        font-size: 16px;
        color: $mainDark;
        font-weight: 700;
        @media only screen and (max-width: 769px) {
          font-size: 14px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 12px;
          white-space: nowrap;
          padding: 5px 8px;
        }
        .additional {
          @media only screen and (max-width: 600px) {
            display: none;
          }
        }
      }

      &.active {
      border-bottom: 3px solid $brandOrange;

        p {
          color: $brandOrange;
        }
        span {
          color: $brandOrange;
        }
      }
    }
  }
}
}
