.offer-details {
  &--container {
    padding-top: 114px;

    &--disclaimer {
      font: normal italic normal 12px/14px Montserrat, serif;

      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }

    &--header {
      font: normal normal normal 14px/16px Montserrat, serif;
      text-align: center;
      letter-spacing: 0;
      color: #404040;
      margin-bottom: 5px;
    }

    &--title {
      font: normal normal normal 14px/32px Montserrat, serif;
      letter-spacing: 0;
      color: #404040;
      margin-bottom: 0;

      a {
        color: #404040;
      }

      &--icon {
        img {
          margin-top: -4px;
        }
      }
    }

    &--filter {
      background: #ffff 0% 0% no-repeat padding-box;
      box-shadow: 0 10px 20px #0000001a;
      border-radius: 10px;
      opacity: 1;
      padding: 33px 7px;
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }

      &--mobile {
        display: block;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      p {
        text-align: left;
        font: normal normal normal 16px/16px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
        margin-bottom: 10px;
      }
    }

    &--info {
      &--details {
        &--brand {
          white-space: nowrap;
          text-align: left;
          font: normal normal normal 20px/26px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 0;

          @media only screen and (min-width: 720px) {
            font: normal normal normal 28px/32px Montserrat, serif;
          }

          @media only screen and (min-width: 768px) and (max-width: 991px) {
            font: normal normal normal 28px/32px Montserrat, serif;
            font-size: 1.2rem;
          }

          @media only screen and (min-width: 1200px) {
            font: normal normal normal 32px/38px Montserrat, serif;
          }
        }

        &--model {
          text-align: left;
          font: normal normal normal 12px/15px Montserrat, serif;
          letter-spacing: 0.28px;
          color: black;
          opacity: 1;

          @media only screen and (min-width: 720px) {
            font: normal normal normal 18px/20px Montserrat, serif;
            color: #0062aa;
          }

          @media screen and (min-width: 1200px) {
            font: normal normal normal 20px/30px Montserrat, serif;
            color: #0062aa;
          }
        }

        &--price {
          width: 50%;
          display: flex;
          align-items: flex-end;
          flex-direction: column;

          &--net {
            text-align: right;
            font: normal normal bold 16px/26px Montserrat, serif;
            letter-spacing: 0;
            color: #f6a335;
            opacity: 1;
            margin-bottom: 0;

            @media only screen and (min-width: 720px) {
              font: normal normal bold 20px/26px Montserrat, serif;
            }

            @media only screen and (min-width: 768px) {
              font: normal normal bold 14px/16px Montserrat, serif;
            }

            @media only screen and (min-width: 992px) {
              font: normal normal bold 20px/26px Montserrat, serif;
            }

            @media screen and (min-width: 1200px) {
              text-align: left;
              font: normal normal bold 24px/28px Montserrat, serif;
            }
          }

          &--gross {
            text-align: right;
            font: normal normal normal 12px/15px Montserrat, serif;
            letter-spacing: 0;
            color: #f6a335;
            opacity: 1;

            @media only screen and (min-width: 720px) {
              font: normal normal normal 18px/20px Montserrat, serif;
            }

            @media only screen and (min-width: 768px) {
              font: normal normal normal 12px/16px Montserrat, serif;
            }

            @media only screen and (min-width: 992px) {
              font: normal normal normal 20px/26px Montserrat, serif;
            }

            @media screen and (min-width: 1200px) {
              text-align: left;
              font: normal normal normal 20px/30px Montserrat, serif;
            }
          }
        }

        &--specification {
          text-align: left;
          font: normal normal normal 12px/15px Montserrat, serif;
          letter-spacing: 0;
          opacity: 1;

          @media only screen and (min-width: 720px) {
            font: normal normal normal 14px/17px Montserrat, serif;
          }

          @media screen and (min-width: 1200px) {
            text-align: left;
            font: normal normal normal 20px/30px Montserrat, serif;
          }
        }
      }

      &--gray-bar {
        &::before {
          content: "";
          background: #f3f3f3 0% 0% no-repeat padding-box;
          width: 50vw;
          left: -50vw;
          height: 100%;
          position: absolute;
          display: none;

          @media screen and (min-width: 768px) {
            display: block;
          }
        }

        background: #f3f3f3 0% 0% no-repeat padding-box;
        border-radius: 15px;
        opacity: 1;
        position: relative;

        @media screen and (min-width: 768px) {
          border-radius: 0 15px 15px 0;
        }

        &--title {
          text-align: left;
          font: normal normal 300 10px/15px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 0;

          @media only screen and (min-width: 720px) {
            font: normal normal 300 11px/20px Montserrat, serif;
          }

          @media screen and (min-width: 1200px) {
            font: normal normal 300 12px/20px Montserrat, serif;
          }
        }

        &--value {
          text-align: left;
          font: normal normal normal 14px/27px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 0;

          @media only screen and (min-width: 720px) {
            font: normal normal normal 15px/38px Montserrat, serif;
          }

          @media screen and (min-width: 1200px) {
            font: normal normal normal 18px/38px Montserrat, serif;
          }
        }
      }
    }

    &--icons {
      p {
        text-align: center;
        font: normal normal 12px/15px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
      }
    }

    &--contact-button {
      a {
        width: 100%;

        @media screen and (min-width: 768px) {
          width: 380px;
        }
      }
    }

    &--images {
      background: lightcoral;
      height: 200px;

      @media screen and (min-width: 768px) {
        height: 495px;
      }
    }

    &--description {
      padding-top: 15px;

      &--title {
        text-align: left;
        font: normal normal 16px/16px Montserrat, serif;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;

        @media screen and (min-width: 768px) {
          font: normal normal 22px/16px Montserrat, serif;
        }
      }

      &--content {
        text-align: left;
        overflow-wrap: break-word;
        font: normal normal normal 12px/16px Montserrat, serif;
        letter-spacing: 0.22px;
        color: #404040;
        opacity: 1;

        @media screen and (min-width: 768px) {
          font: normal normal 14px/16px Montserrat, serif;
        }
      }
    }
  }
}
