.dealer-account {
.dealer-account–options {
    .tabs-typo {
       p{
          &::first-letter{
              text-transform: uppercase;
          }
       }
    }
    .content {
        .error {
            font-size: 12px;
            margin: -5px 1px 1px 25%;
            @media only screen and (max-width: 576px) {
                font-size: 10px;
                margin: 1px;
            }
        }
        .container {
            padding-top: 5px;
        }
        .input-label-small {
            label {
                width: 30%;
                @media only screen and (max-width: 576px) {
                    width: 100%;
                }
            }

        }
        .submit{
            margin-top: 15px;
            button{
                width: 100%;
            }
        }
        #invoice-form {
            .error {
                display: block;
                .errorlist {
                    padding: 0;
                }
            }
        }
    }
    &.option-email {
        @media only screen and (max-width: 576px) {
            font-size: 12px;
        }
        .error {
            display: block;
            font-size: 24px;
            height: 24px;
            margin: -24px auto 10px auto;
            text-align: center;
        }
        .form-group{
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 991px) {
              flex-wrap: wrap;
            }
            input{
                width: 49%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    &.option-super-email {
        @media only screen and (max-width: 576px) {
            font-size: 12px;
            h4 {
                font-size: 16px;
            }
            h5 {
                font-size: 14px;
            }
        }
        .error {
            display: block;
            font-size: 24px;
            height: 24px;
            margin: -24px auto 10px auto;
            text-align: center;
        }
        .form-group{
            input{
                margin-bottom: 15px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
    &.option-password{
        @media only screen and (max-width: 576px) {
            font-size: 12px;
        }
        .error {
            display: block;
            font-size: 24px;
            height: 24px;
            margin: -24px auto 10px auto;
            text-align: center;
        }
        .form-group{
            input{
                margin-bottom: 15px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}
}