@import "../../../../global/homepage/scss/colors.scss";

.homepage-wrapper {
  background-color: $mainLight;
  position: relative;
  overflow: hidden;

  .homepage-stats-section {
    background-color: white;
    position: relative;
    overflow: hidden;
    border-top: 5px solid $mainLight;
    .container {
      text-align: center;
    }
  }
}


