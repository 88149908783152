.dealer-account {
.dealer-account–marketing {
    .tabs-typo {
       p {
          &::first-letter {
              text-transform: uppercase;
          }
       }
    }
    .content {
        .error {
            font-size: 12px;
            margin: -5px 1px 1px 25%;
            @media only screen and (max-width: 576px) {
                font-size: 10px;
                margin: 1px;
            }
        }
        .container {
            padding-top: 5px;
            .qr-codes {
                display: flex;
                flex-wrap: wrap;
                .qr-codes-content {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    justify-content: space-evenly;
                    width: 100%;
                    figure {
                        width: 300px;
                        figcaption {
                            width: 100%;
                            text-align: center;
                            margin: 10px;
                        }
                    }
                }
            }
        }
        .work-in-progress-img {
            width: 50%;
            @media only screen and (max-width: 900px) {
                width: 100%;
            }
            height: auto;
            border-radius: 5%;
        }
    }
    &.option-qr-codes {
        @media only screen and (max-width: 576px) {
            font-size: 12px;
        }
        .error {
            display: block;
            font-size: 24px;
            height: 24px;
            margin: -24px auto 10px auto;
            text-align: center;
        }
    }
}
}