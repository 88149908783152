.bg-light {
  background-color: $mainLight !important;
}

.stars-wrapper {
  display: flex;
  flex-wrap: nowrap;
  .rate,
  .stars {
    white-space: nowrap;
  }
}

.nowrap {
  white-space: nowrap;
}

@mixin arrow-base {
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.5s;
}
@mixin center-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.arrow-orange {
  border: solid $brandOrange;
  @include arrow-base;
}

.arrow-blue {
  border: solid $brandBlue;
  @include arrow-base;
}

.arrow-main {
  border: solid $mainDark;
  @include arrow-base;
}

.arrow-right {
  transform: rotate(-45deg);
}

.arrow-left {
  transform: rotate(135deg);
}

.arrow-up {
  transform: rotate(-135deg);
}

.arrow-down {
  transform: rotate(45deg);
}

// BRAND SCROLL-BAR

.brand-scroll-bar::-webkit-scrollbar {
  width: 10px;
}
.brand-scroll-bar::-webkit-scrollbar-track {
  background: $mainLight;
}
.brand-scroll-bar::-webkit-scrollbar-thumb {
  background: $brandOrange;
  border: 1px solid $brandOrange;
}

.stars-normal-size {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: bold;
  .rate-star-wrapper {
    margin-right: 10px;
    margin-bottom: 4px;
    display: flex;
  }
  .star-generate {
    @include center-item;
    flex-direction: row-reverse;
    margin-right: 5px;
  }
}

.stars-small-size {
  @include center-item;
  font-size: 12px;
  .rate-star-wrapper {
    margin-right: 5px;
    margin-bottom: 2px;
    .rate-star {
      width: 10px;
      height: 10px;
      margin-left: 1px;
    }
  }
  .star-generate {
    @include center-item;
    flex-direction: row-reverse;
    margin-right: 5px;
  }
}

.important-text-round {
  background-color: $brandBlue;
  padding: 3px 10px;
  border-radius: 6px;
  width: fit-content;
  color: $mainLight;
  font-size: 12px;
  @media only screen and (min-width: 768px) {
    padding: 6px 21px;
    border-radius: 10px;
    font-weight: bold;
  }
}

.icon-convenience {
  display: flex;
  flex-wrap: wrap;
  .icon {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 8px;
    background-color: $backgroundWhite;
    box-shadow: 0px 0px 16px rgba($fullBlack, 0.05);
    justify-content: flex-start;
    border-radius: 10px;
    img {
      height: 30px;
      width: auto;
      max-width: 80px;
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      @media only screen and (min-width: 768px) {
        max-width: none;
      }
    }

    p {
      margin: 0;
      font-size: 10px;
      text-align: center;
    }
    @media only screen and (min-width: 768px) {
      width: 160px;
      padding: 24px 12px;
      img {
        height: 47px;
        width: auto;
        margin-bottom: 23px;
      }
    }
  }
}

#main-loader-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: $overlayColor;
  display: none;
  justify-content: center;
  align-items: center;
}

#main-loader {
  border: 16px solid $mainLight;
  border-top: 16px solid $brandOrange;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error {
  color: $alertError;
  margin: 5px;
  font-size: 16px;
  display: none;
  @media only screen and (min-width: 768) {
    font-size: 12px;
  }
}

.logout-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mainLight;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: calc(100vh - 90px - 260px);
  h1 {
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
  }
  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 90px - 160px);
  }
  @media only screen and (min-width: 1200) {
    min-height: calc(100vh - 90px - 180px);
  }
}

.chosen-card {
  box-shadow: 0 0 0 2px $brandBlue !important;
}

#popup-messages-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;

  .popup-message-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    .popup-message {
      padding: 10px 15px;
      width: 100%;
      font-size: 24px;
      line-height: 1.2;
      color: $mainLight;
      text-align: center;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .popup-message-wrapper {
      min-height: 80px !important;
      .popup-message {
        font-size: 14px !important;
      }
    }
  }
}

#popup-onsave-message-wrapper {
  flex-direction: row !important;
  padding: 10px 15px;
  max-height: none;
  @media only screen and (max-width: 991px) {
    flex-direction: column !important;
    p {
      flex-grow: 0;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

.success-popup {
  background: $alertCorrect;
}
.error-popup {
  background: $alertError;
}

// Unsaved changes confirmation popup
.unsaved-popup {
  min-height: 72px;
  background: $textLight 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px $shadowButton;

  p {
    color: $fullBlack;
    text-align: right;
    font-weight: 500;
    font-size: inherit;
    letter-spacing: 0;
    display: flex;
    flex-grow: 1;
    margin: auto;
  }

  button {
    width: 161px;
    height: 49px;
    border-radius: 15px;
    color: $textLight;

    &:first-of-type {
      background: $brandBlue 0 0 no-repeat padding-box;
      margin-right: 15px;
    }
    &:last-of-type {
      background: $alertError 0 0 no-repeat padding-box;
    }
  }

  @media only screen and (max-width: 767px) {
    p {
      font-size: 14px;
    }
    button {
      border-radius: 10px;
      padding: 0 10px;
      height: 40px;
      width: 120px;
      line-height: 40px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
