.worker-account {
#photo-error {
  font-size: 12px;
  color: $alertError;
  margin-top: 15px;
  display: none;
}

#photo-agreement-error {
  font-size: 12px;
  color: $alertError;
  margin-top: 15px;
  display: none;
}

#user-photo {
  width: 100%;
  height: 100%;
}

#added-photo {
  position: relative;
  display: none;
}

#agreement-checkbox-div {
  font-size: 12px;
  padding-top: 20px;
  button {
    background:none;
    border:none;
    display: inline;
    color: blue;
  }
  p {
    display: none;
  }
  #agreement-collapse-btn {
    display: none;
  }
  label {
    display: inline;
  }
}

#delete-photo {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.day-off-data {
  p {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .trash-icon {
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
}

.disabled-switch {
  border-color: $disabledColor !important;
  color: $disabledColor !important;
  box-shadow: none !important;
}

.bold-text {
  color: $mainDark !important;
  font-weight: bold !important;
}

.worker-account–overall {
  background-color: $mainLight;
  margin-top: 90px;
  .form {
    padding: 0 !important;
  }
  .error {
    font-size: 12px;
    height: 24px;
    margin-top: -19px;
    margin-bottom: 1px;
  }
  .error-space {
    margin-left: 35%;
    @media only screen and (max-width: 576px) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
  .main-tab {
    background-color: $mainLight;

    .container {
      padding-left: 0;
    }

    .tabs {
      margin-bottom: 0;
    }
  }

  .error {
    display: block;
    &:empty {
      display: none;
    }
  }

  .sub-tab {
    .button-secondary {
      @media only screen and (max-width: 615px) {
        display: none;
      }
    }

    .container {
      background-color: $backgroundWhite;
      padding: 15px 20px;

      @media only screen and (max-width: 576px) {
        padding: 15px 10px;
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .tabs-typo {
      .tabs {
        @media only screen and (max-width: 576px) {
          margin: 0;
        }
      }
    }
  }

  section.content {
    .container {
      background-color: $backgroundWhite;

      .content-subscription-expired {
        margin: 75px 100px;

        @media only screen and (max-width: 576px) {
          margin: 0;
        }

        .expired-header {
          margin-bottom: 58px;

          @media only screen and (max-width: 576px) {
            margin-bottom: 30px;
          }

          p {
            color: $brandBlue;
            font-size: 24px;
            font-weight: bold;

            @media only screen and (max-width: 576px) {
              font-size: 22px;
            }
          }
          p:first-of-type {
            font-size: 51px;
            display: flex;
            align-items: center;
            &::after {
              content: "";
              display: block;
              background-image: url(../../../static/images/smile.png);
              background-repeat: no-repeat;
              width: 59px;
              height: 59px;
              background-size: contain;
              margin-left: 15px;

              @media only screen and (max-width: 768px) {
                width: 80px;
                height: 80px;
              }

              @media only screen and (max-width: 390px) {
                width: 65px;
                height: 65px;
              }
            }

            @media only screen and (max-width: 576px) {
              font-size: 28px;
            }
          }
        }

        .expired-text {
          font-size: 20px;
          margin-bottom: 178px;

          @media only screen and (max-width: 576px) {
            margin-bottom: 45px;
          }
        }

        .expired-contact {
          font-size: 18px;
        }
      }
    }

    .content-element {
      margin-bottom: 30px;

      @media only screen and (max-width: 576px) {
        margin-bottom: 20px;
      }

      .input-label {
        border-top: 1px solid rgba($mainDark, 0.1);
        padding-top: 18px;
        margin-bottom: 0;

        @media only screen and (max-width: 576px) {
          padding-top: 15px;
        }
      }

      .element-header {
        border-top: 1px solid rgba($mainDark, 0.1);
        padding-top: 18px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        position: relative;

        @media only screen and (max-width: 576px) {
          padding-top: 15px;
          margin-bottom: 15px;
        }

        p {
          margin: 0;

          @media only screen and (max-width: 576px) {
            font-size: 14px;
          }
        }

        .element-title {
          white-space: nowrap;
          font-weight: 500;
        }

        .box-tooltip {
          position: absolute;
          top: -5px;
          left: 215px;
          position: absolute;
          z-index: 2;
          @media only screen and (max-width: 576px) {
            top: 0px;
            left: 150px;
          }
          .tooltip-info {
            @media only screen and (max-width: 366px) {
              width: 140px;
            }
          }
        }
      }

      .badges-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &.collapse {
          overflow: hidden;
          height: 0;
        }

        &.show {
         height: auto;
        }
      }

      .box-badges {
        border-radius: 15px;
        box-shadow: 0px 0px 16px rgba($mainDark, 0.1);
        padding: 20px 30px;

        @media only screen and (max-width: 576px) {
          padding: 15px;
        }

        .subtitle {
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba($mainDark, 0.1);

          @media only screen and (max-width: 576px) {
            padding-bottom: 10px;
          }
        }

        > .active,
        .inactive {
          margin-top: 18px;
          margin-bottom: 18px;
          width: 100%;
        }

        > .active {
          @media only screen and (max-width: 576px) {
            margin-top: 0;
          }
        }

        .badge,
        .badge-icon {
          margin-bottom: 7px;
          margin-right: 7px;
        }

        .bottom {
          text-align: right;
          margin-top: 15px;
        }
      }

      .custom-calendar-input-wrapper {
//         display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media only screen and (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          margin-bottom: 0;
          margin-right: 20px;
          max-width: calc(100% - 182px);
          @media only screen and (max-width: 576px) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
          }
        }

        .input-custom-data {
          display: flex;
          padding: 5px 15px;
          height: 50px;
          min-width: 180px;
          align-items: center;
          border-radius: 10px;
          border: 1px solid $mainDark;
          @media only screen and (max-width: 650px) {
            margin-top: 20px;
            max-width: 200px;
          }
          @media only screen and (max-width: 576px) {
            margin-top: 0;
          }

          img {
            height: 28px;
            width: auto;
            margin-right: 8px;
          }

          span {
            font-size: 16px;
            line-height: 38px;
            @media only screen and (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }

      .add-img {
        width: 346px;
      }
    }
  }
  &.option-email {
    .submit {
      margin-top: 15px;
      button {
        width: 100%;
      }
    }
    .error {
      font-size: 20px;
      text-align: center;
    }
    .form-group {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 991px) {
        flex-wrap: wrap;
      }
      input {
        width: 49%;
        @media only screen and (max-width: 991px) {
          width: 100%;
          &:first-of-type {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  &.option-password {
    .submit {
      margin-top: 15px;
      button {
        width: 100%;
      }
    }
    .error {
      font-size: 20px;
      text-align: center;
    }
    .form-group {
      input {
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.time-picker-label {
  .pickers-opening-hours {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    .range-time-picker {
      width: fit-content;
      .time-pickers {
        margin: 0;
        input.small-time-picker {
          padding: 5px;
          font-size: 12px;
          width: 80px;
          text-align: center;
        }
      }
      .separator {
        margin: 0 5px;
      }
    }
    .switch-general {
      padding: 0;
      width: inherit;
      margin-left: 60px;
      min-width: 70px;
      max-width: 90px;
      label {
        font-size: 14px;
        width: 100%;
        color: $notActiveText;
      }
      .custom-control-label::before {
        height: 16px;
        top: 8px;
        right: 0;
        width: 1.75rem;
      }
      .custom-control-label::after {
        top: 10px;
        right: 14px;
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
      }
    }
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .time-picker-label {
    .pickers-opening-hours {
      .range-time-picker {
        .time-pickers {
          input.small-time-picker {
            padding: 3px;
            font-size: 11px;
            width: 80px;
          }
        }

        .separator {
          margin: 0 2px;
        }
      }
      .switch-general {
        margin-left: 45px;
        max-width: 80px;
        label {
          font-size: 12px;
        }
        .custom-control-label::before {
          top: 6px;
          width: 1.75rem;
        }
        .custom-control-label::after {
          top: 8px;
          width: calc(1rem - 4px);
          height: calc(1rem - 4px);
        }
      }
    }
  }
}
}