@import "../../../../global/homepage/scss/colors.scss";

.dealer-card-medium-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px $shadowColor;
  width: 350px;
  border-radius: 10px;

  &:nth-child(even) {
    .dealer-card-content
      .dealer-are-you-an-owner-wrapper
      .question-mark
      .dealer-are-you-an-owner-popup {
      left: auto;
      right: -0.5rem;
      .dealer-are-you-an-owner-popup-content {
        order: -1;
      }
    }
  }
  .dealer-card-content {
    flex: 1 1 auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    .star-generate-wrapper {
      font-weight: normal;
    }
    .dealer-name {
      display: flex;
      justify-content: space-between;
      margin: 10px 0 5px;

      a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 20px;
        line-height: 1.2;
        color: $mainDark;
        text-decoration: none;
      }
    }
    .dealer-are-you-an-owner-wrapper {
      display: none;

      @media only screen and (max-width: 1200px) {
        display: block;
      }

      .question-mark {
        position: relative;
        background: $brandOrange;
        color: white;
        height: 1.15em;
        width: 1.15em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        font-size: 14px;

        &.white {
          background: $backgroundWhite;
          color: $brandOrange;
        }

        &:hover > .dealer-are-you-an-owner-popup {
          opacity: 1;
          visibility: visible;
        }

        &:active > .dealer-are-you-an-owner-popup {
          opacity: 1;
          visibility: visible;
        }

        .dealer-are-you-an-owner-popup {
          display: flex;
          justify-content: space-between;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: -1rem;
          left: -0.5rem;
          background: $brandOrange;
          width: 260px;
          text-align: center;
          padding: 1rem 0.5rem;
          transition: opacity 0.33s;

          .dealer-are-you-an-owner-popup-content {
            margin: 0 auto;
          }

          button {
            width: 100%;
            margin-top: 0.5rem;
          }
        }
      }
    }
    .dealer-type {
      margin-top: 2px;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    .dealer-address {
      margin-top: 8px;
      font-size: 12px;
      margin-bottom: 0;
    }
    .dealer-link {
      margin-top: 20px;
      // Fit cards footer to bottom
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      .arrow-blue {
        padding: 4px;
      }
      span {
        margin-right: 15px;
        color: $brandBlue;
        font-size: 15px;
      }
      a {
        text-decoration: none !important;
      }
    }
    .dealer-marks {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      .dealer-mark {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
  .dealer-card-medium-footer {
    background: $mainLight;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      display: none;
    }

    .footer-text-content {
      font-size: 14px;
      width: 110px;
      line-height: 1;
      margin: 0;
    }
    a {
      text-decoration: none !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .dealer-card-content
      .dealer-are-you-an-owner-wrapper
      .question-mark
      .dealer-are-you-an-owner-popup {
      left: auto;
      right: -0.5rem;
      .dealer-are-you-an-owner-popup-content {
        order: -1;
      }
    }
  }
}
