.worker-account {
.modal-exceptions {
  .custom-control-label::after {
    width: 14px;
    height: 14px;
  }

  .custom-control-label::before {
    height: 18px !important;
    width: 30px !important;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;

    .columns-wrapper {
      display: flex;
      padding: 30px;
      @media only screen and (max-width: 576px) {
        padding: 30px 15px;
      }
      .date-col {
        width: 320px;
        .input-label-small {
          width: 100%;
          padding-right: 30px;
          label {
            width: 40px;
          }
          .custom-calendar-input-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            @media only screen and (max-width: 576px) {
              flex-direction: column;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            p {
              font-size: 16px;
              margin-bottom: 0;
              margin-right: 30px;
              max-width: calc(100% - 182px);
              @media only screen and (max-width: 576px) {
                max-width: 100%;
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
              }
            }
          }
        }
      }
      .exceptions-col {
        min-width: 320px;
        @media only screen and (max-width: 576px) {
          min-width: 0;
        }
        .button-primary {
          width: 100%;
          margin-top: 15px;
        }
        .custom-switch {
          margin-bottom: 10px;
        }
        .custom-calendar-input-wrapper {
          #daterange {
            min-width: 260px;
          }
        }
        .range-time-picker {
          margin-top: 30px;
          .label {
            margin-left: 5px;
          }
          .time-pickers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 15px;
            input {
              width: 45%;
              min-width: 160px;
              border-radius: 10px;
              border: 1px solid $mainDark;
              padding: 10px 20px;
              &:focus {
                outline: none;
                border-color: $brandOrange;
              }
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              @media only screen and (max-width: 576px) {
                min-width: 120px;
              }
            }
            .separator {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  .opinions-settings {
    .form-group {
      .custom-switch {
        label {
          width: 100%;
          margin-right: 20px;
          line-height: 18px;
        }
        .custom-control-label::after {
          right: -23px;
        }
      }
    }
  }
}
}
