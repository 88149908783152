@import "../../../../global/homepage/scss/colors.scss";

.without-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@mixin select-option {
  border: 1px solid;
  height: 35px;
  width: 100%;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  transition: border-radius 0.5s;
}

#error-handler {
  color: $alertError;
  font-size: 12px;
  margin-top: -20px;
  text-align: center;
  display: none;
}

.secondary-form-wrapper {
  background: $backgroundWhite;
  border-radius: 10px;
  z-index: 9;
  .secondary-form-inputs {
    display: flex;
    flex-direction: column;
    .secondary-form-inputs-col {
      width: 100%;
      padding: 2px 25px;
      max-width: 340px;
      margin: 10px auto;
      .title-input {
        margin-bottom: 5px;
        margin-left: 10px;
        font-size: 16px;
      }
      .checkbox-certificated-dealers {
        margin-top: 20px;
        position: absolute;
        bottom: 100px;
        .custom-control-label {
          cursor: pointer;
        }
        span {
          font-size: 16px;
          margin-left: 15px;
          position: absolute;
          top: 2px;
          white-space: nowrap;
        }
      }
      .mark-option {
        width: 100%;
        background: transparent;
      }
      .search-tabs {
        display: flex;
        width: fit-content;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        .search-tab {
          width: 50%;
          border-top: 1px solid;
          border-bottom: 1px solid;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .search-tab-left {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-left: 1px solid;
        }
        .search-tab-right {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-right: 1px solid;
        }
        .search-active-tab {
          border: 0 !important;
          background-color: $brandOrange;
          color: $textLight;
        }
      }
      .search-wrapper-without-title {
        margin: 28px auto 5px auto !important;
      }
      .search-wrapper {
        width: fit-content;
        width: 100%;
        margin: 5px auto 5px auto;
        position: relative;
        .search-options-mark {
          @include select-option;
          border-color: $mainDark;
          color: $mainDark;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          #mark-input-name-input,
          #mark-input-name-input:focus {
            border-style: none;
            outline: none;
          }
          #mark-input-name,
          #mark-input-name:focus {
            border-style: none;
            outline: none;
            display: none;
          }
        }
        .search-options-place {
          @include select-option;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          #localization-input-name-input,
          #localization-input-name-input:focus {
            border-style: none;
            outline: none;
          }
          #localization-input-name,
          #localization-input-name:focus {
            border-style: none;
            outline: none;
            display: none;
          }
        }
        .collapse-mark {
          border: 1px solid $mainDark;
        }
        .collapse-place {
          border: 1px solid $mainDark;
        }
        .collapse-search {
          position: absolute;
          border-top: 0;
          padding: 0;
          width: 100%;
          max-height: 250px;
          overflow: auto;
          background: $backgroundWhite;
          z-index: 7;
          &.in {
            display: block;
          }
          .custom-control {
            margin: 4px 10px;
            min-height: 24px;
            &:first-of-type {
              margin-top: 10px;
            }
            &:last-of-type {
              margin-bottom: 24px;
            }
          }
          .custom-control-label {
            margin-top: 5px;
            span {
              font-size: 14px;
              margin-left: 2px;
              position: absolute;
              top: 4px;
              white-space: nowrap;
            }
          }
        }
        div[class^="search-options"] {
          &.in + .collapse-search {
            z-index: 8;
          }
        }
      }
    }
  }
  .secondary-form-button {
    width: 300px;
    margin: 60px auto 30px auto;
    .button-primary-full {
      &:after {
        content: "Zastosuj";
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .secondary-form-wrapper {
    z-index: 1;
    .secondary-form-inputs {
      margin: 0 auto;
      .secondary-form-inputs-col {
        max-width: 500px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .secondary-form-wrapper {
    // width must be specified as we get out of mobile view
    // and panel is no longer presented as modal
    width: 1200px;
    height: 250px;
    .secondary-form-inputs {
      flex-direction: row;
      .secondary-form-inputs-col {
        width: calc(100% / 3);
        padding: 25px;
        .checkbox-certificated-dealers {
          position: unset;
        }
      }
    }
    .secondary-form-button {
      width: 500px;
      margin: 0 auto 30px auto;
    }
  }
}
